import React, { useState } from "react";
import { orgPermissions } from "utils/OrgPermissions";
import { getTableConfig } from "./bookedSessionTableConfig";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useStyles from "./BookedSession.styles";
import ExlyTable from "common/Components/ExlyTable";
import { api, dataProvider } from "data";
import AddQuestionsSuccess from "./components/AddQuestionsSuccess";
import QuestionsFormModal from "./QuestionsFormModal";
import ActionFieldCustom from "ui/Fields/ActionFieldCustom";
import MoreActions from "./components/MoreActions";
import { Button } from "@my-scoot/component-library-legacy";
import { useNotifications, useToggleState } from "utils/hooks";
import arrayMutators from "final-form-arrays";
import { Form } from "react-final-form";
import { useRefresh } from "react-admin";
import { addQuestionsValidations } from "schedule-v2/commonPages/Pricing.utils";
import { useParams } from "react-router-dom";
import { Header } from "common/Components/Header";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import styles from "./Style.module.css";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { BOOKING_QUESTIONS_LEARN_MORE_HREFS } from "./constants";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
import { EXLY_TABLE_EMPTY_STATES_VERSIONS } from "common/Components/ExlyTable/ExlyTableEmptyState/constants/ExlyTableEmptyState.constants";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";

const initial_state = {
  questionObj: {},
};

const BookedQuestions = (props) => {
  const classes = useStyles();
  const isDesktop = useIsDesktop();
  const [questionsModal, setQuestionsModal] = useState(false);
  const [isModalActive, showModal, hideModal] = useToggleState(false);
  const { listing_uuid } = useParams();
  const { notify } = useNotifications();
  const refetch = useRefresh();
  const {
    isOnboardedPrevVal: isFeatureVisited,
    flagLoading: isFeatureFlagLoading,
    handleFeatureOnboardChange,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.KNOWLEDGE_BASE.MANAGE_BOOKING_QUESTIONS,
    successCallback: (marked) => !marked && handleFeatureOnboardChange(true),
  });

  const secondaryComponent = (
    <div className="d-flex">
      <LearnMoreCta
        href={BOOKING_QUESTIONS_LEARN_MORE_HREFS.BOOKING_QUESTIONS}
      />
      <Button
        color="primary"
        size="medium"
        onClick={() => setQuestionsModal(true)}
        className="ml-3"
      >
        Add Question
      </Button>
    </div>
  );

  const onModalClose = () => {
    setQuestionsModal(false);
    hideModal();
    refetch();
  };

  const getAllQuestions = async () => {};

  const addNewQuestion = async (formState) => {
    const { questionObj } = formState;

    if (!addQuestionsValidations(questionObj, notify)) {
      return;
    }

    try {
      const response = await dataProvider.custom_request(
        api.questions.add_questions,
        "POST",
        {
          listing_uuid: listing_uuid,
          ...questionObj,
        }
      );
      if (response.status === 200) {
        getAllQuestions();
        showModal();
        props.openModal();
      }
      setQuestionsModal(false);
    } catch (err) {
      console.error("error while adding question", err);
    }
  };

  const footerProps = {
    primarybtn: "Add Question",
    primaryClick: () => setQuestionsModal(true),
    hidePrimarybtn: !orgPermissions.canCreate(),
  };

  const openModal = () => {
    setQuestionsModal(false);
    showModal();
  };

  const openQuestionsModal = () => {
    setQuestionsModal(true);
    hideModal();
  };

  const closeModal = () => {
    setQuestionsModal(false);
    window.location.reload(); //Need to reload the window to fetch latest data
  };

  const bookingsTitle = (
    <span className={classes.page_title}>
      <span onClick={() => window.history.go(-1)} className="pointer">
        Booking Questions{" "}
      </span>
    </span>
  );

  return (
    <div className="component-wrapper">
      {isDesktop && (
        <Header
          title="Booking Questions"
          right={secondaryComponent}
          classNames={{ container: styles.headerContainer }}
        />
      )}

      <ExlyTable
        ra_props={{
          ...props,
          exporter: false,
        }}
        noExportButton={!orgPermissions.canExport()}
        tableTitle={!isDesktop && bookingsTitle}
        footerProps={footerProps}
        hasMobileFooter={true}
        selectedTab="booked-questions"
        columnConfig={getTableConfig()}
        seperateTabFilter={isDesktop}
        layoutProps={{
          paddingDesktop: "0",
          paddingBottom: "120px",
          layoutDesktopMargin: "12px 0 0 19px",
          noMobileBoxShadow: true,
          learnMoreHref: BOOKING_QUESTIONS_LEARN_MORE_HREFS.BOOKING_QUESTIONS,
        }}
        fieldsLeftPadded
        borderedFields
        drawerFieldsBordered
        fieldsAlignment="space-between"
        drawerFieldsAlignment="space-between"
        recordFooterVariant="secondary"
        primaryKey="id"
        customPrimaryAction={{
          renderCustomAction: (record) => (
            <ActionFieldCustom
              show_more
              record={record}
              sortable={false}
              emptyText={"N/A"}
              ActionField={MoreActions}
              Nobutton={true}
            />
          ),
        }}
        emptyStateProps={{
          isLoading: isFeatureFlagLoading,
          version: EXLY_TABLE_EMPTY_STATES_VERSIONS.V2,
          title: "No booking questions yet",
          description:
            "Add questions which you want your customers to answer before or after booking. View the video to learn more.",
          videoSrc: BOOKING_QUESTIONS_LEARN_MORE_HREFS.BOOKING_QUESTIONS_VIDEO,
          playerProps: { playing: !isFeatureFlagLoading && !isFeatureVisited }, // autoplay the knowledge base video on the first visit to this feature
          primaryCtaProps: isDesktop && {
            title: "Add Booking Question",
            onClick: () => setQuestionsModal(true),
          },
          secondaryCtaProps: {
            variant: EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
            learnMoreHref: BOOKING_QUESTIONS_LEARN_MORE_HREFS.BOOKING_QUESTIONS,
          },
          className: "mt-3",
        }}
      />
      <Form
        initialValues={initial_state}
        onSubmit={addNewQuestion}
        mutators={{
          ...arrayMutators,
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {questionsModal && (
              <QuestionsFormModal
                title={"Add Booking Question"}
                openModal={openModal}
                isOpen={questionsModal}
                closeModal={closeModal}
                addNewQuestion={addNewQuestion}
                initial_state={initial_state}
                handleSubmit={handleSubmit}
                ctaText={"Add Question"}
              />
            )}
            {isModalActive && (
              <AddQuestionsSuccess
                open={isModalActive}
                onClose={onModalClose}
                classes={classes}
                openQuestionsModal={openQuestionsModal}
              />
            )}
          </form>
        )}
      </Form>
    </div>
  );
};

export default withComponentLibraryTheme(BookedQuestions);
