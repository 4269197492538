import React, { Suspense } from "react";
import { Form } from "react-final-form";

import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useLeaderboard from "./useLeaderboard";

import { LEADERBOARD_TABS } from "./leaderboard.data";

import LoadingSpinner from "schedule-v2/modals/recorded-content/LoadingSpinner";
import Header from "./components/Header/Header";
import Tabs from "./components/Tabs/Tabs";

import { CircularProgress } from "@material-ui/core";
import { notification_color_keys } from "utils/hooks";
import { LeaderboardContext } from "./LeaderboardContext";
import styles from "./leaderboard.module.css";

// pages imports
const Scoreboard = React.lazy(() =>
  import("./components/pages/Scoreboard/Scoreboard")
);
const Settings = React.lazy(() =>
  import("./components/pages/Settings/Settings")
);

const Leaderboard = (props) => {
  const leaderboardData = useLeaderboard();
  const {
    tab,
    settingsConfig,
    isScoreboardTab,
    isSettingsTab,
    loading,
    listingUuid,
    notify,
    handleTabChange,
    handleSaveSettings,
  } = leaderboardData;

  return (
    <LeaderboardContext.Provider value={leaderboardData}>
      <Form initialValues={settingsConfig} onSubmit={handleSaveSettings}>
        {({ handleSubmit, submitting, invalid, errors, pristine }) => {
          const handleFormSubmit = (e) => {
            if (invalid)
              Object.values(errors).forEach((error) =>
                notify(error, notification_color_keys.error)
              );
            handleSubmit(e);
          };

          return (
            <form onSubmit={handleFormSubmit}>
              <Header
                onClick={handleFormSubmit}
                loading={submitting}
                showCta={!pristine && tab === LEADERBOARD_TABS.SETTINGS}
              />

              <div className={styles.body}>
                <Tabs value={tab} onChange={handleTabChange} />
                {loading ? (
                  <div className="text-center mt-2">
                    <CircularProgress />
                  </div>
                ) : (
                  <Suspense fallback={<LoadingSpinner />}>
                    {isScoreboardTab && (
                      <Scoreboard
                        reactAdminProps={props}
                        onTabChange={handleTabChange}
                        listingUuid={listingUuid}
                      />
                    )}
                    {isSettingsTab && <Settings reactAdminProps={props} />}
                  </Suspense>
                )}
              </div>
            </form>
          );
        }}
      </Form>
    </LeaderboardContext.Provider>
  );
};

export default withComponentLibraryTheme(Leaderboard);
