import React from "react";
import styles from "./settings.module.css";
import { SCORE_TYPES } from "./settings.enums";

export const SETTINGS_CONSTANTS = {
  MIN_CUSTOMERS_REQUIRED: 4,
};

export const SETTINGS_FORM_KEYS = {
  isCustomerLeaderboardVisible: "is_customer_leaderboard_visible",
  leaderboardScoreType: "leaderboard_score_type",
  isCustomerGoalVisible: "is_customer_goal_visible",
};

export const LABELS = {
  leaderboard: {
    label: "Leaderboard",
    info: "Disabling the leaderboard will hide it from your customers. However, please be aware that this will also limit your access to view or manage the scoreboard and its settings.",
    note: (
      <div>
        <strong>Please note:</strong> the leaderboard will only be visible to
        your course customers after the total number of customers in this course
        exceeds 4.
      </div>
    ),
  },

  scoring: {
    label: "Scoring options",
    info: "We will calculate a customer’s rank by cumulating scores of all conditions you select here.",
  },

  goalVisibility: {
    label: "Goal visibility",
    info: "Would you like to like to share the scoring criteria with the students?",
  },
};

export const GOAL_VISIBILITY_OPTIONS = [
  {
    id: 1,
    label: "Yes",
    value: true,
  },
  {
    id: 2,
    label: "No",
    value: false,
  },
];

export const SCORING_OPTIONS = [
  {
    id: `${SCORE_TYPES.COURSE_COMPLETION}`,
    value: SCORE_TYPES.COURSE_COMPLETION,
    label: (
      <div className={styles.checkboxLabel}>
        Course Completion Percentage
        <div className={styles.checkboxSubLabel}>(Auto-calculated)</div>
      </div>
    ),
  },
  {
    id: `${SCORE_TYPES.EXAMS}`,
    value: SCORE_TYPES.EXAMS,
    label: (
      <div className={styles.checkboxLabel}>
        Total score from exams
        <div className={styles.checkboxSubLabel}>(Auto-calculated)</div>
      </div>
    ),
  },
];
