import React from "react";

import { Tabs as ExlyTabs } from "@my-scoot/component-library-legacy";

import { LEADERBOARD_TAB_OPTIONS } from "../../leaderboard.data";

import styles from "./tabs.module.css";

const Tabs = ({ value, onChange }) => {
  return (
    <ExlyTabs
      tabs={LEADERBOARD_TAB_OPTIONS}
      value={value}
      onChange={onChange}
      tabVariant="outlined"
      classes={{
        root: styles.root,
      }}
    />
  );
};

export default Tabs;
