import React from "react";
import { useListController } from "react-admin";
import { getParameterByName } from "utils/Utils";
import { is_empty } from "utils/validations";
import { getTabConfig } from "../common/tableConfig";

const useTabsConfig = (props) => {
  const [tabConfig, setTabConfig] = React.useState(getTabConfig());
  const { setFilters, filterValues } = useListController(props);

  React.useEffect(() => {
    if (is_empty(filterValues?.listing__uuid)) {
      setTabConfig(getTabConfig());
    }
  }, [filterValues]);

  React.useEffect(() => {
    let manageOfferingFilter = getParameterByName("manageOffering");

    if (!is_empty(manageOfferingFilter)) {
      setFilters({ listing__uuid: manageOfferingFilter });
      setTabConfig(getTabConfig(`?manageOffering=${manageOfferingFilter}`));
    }
  }, []);

  return {
    tabConfig,
  };
};

export default useTabsConfig;
