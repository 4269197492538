import { makeStyles } from "@material-ui/core";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import React from "react";
import classnames from "classnames";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: ({ is_desktop }) => (is_desktop ? 1 : "none"),
    width: ({ is_desktop }) => (is_desktop ? "100%" : "85%"),
    minWidth: 0,
    scrollSnapAlign: "start",
    border: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    borderRadius: "12px",
    height: "340px",
  },
  label: {
    color: theme?.palette?.secondary?.main,
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "20px",
    letterSpacing: "0.04em",
    borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    padding: "0 16px",
    height: "53.5px",
    display: "flex",
    alignItems: "center",
  },
  contentContainer: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    height: "calc(100% - 53.5px)",
  },
}));

const SliderItem = ({
  label,
  children,
  className,
  contentClassName,
  labelClassName,
}) => {
  const is_desktop = useDesktopMediaQuery();

  const classes = useStyles({ is_desktop });

  return (
    <div className={classnames(classes.root, className)}>
      <div className={classnames(classes.label, labelClassName)}>{label}</div>
      <div className={classnames(classes.contentContainer, contentClassName)}>
        {children}
      </div>
    </div>
  );
};

export default withComponentLibraryTheme(SliderItem);
