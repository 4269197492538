import ExlyModal from "common/Components/ExlyModal";
import React from "react";
import QuestionsForms from "schedule-v2/commonPages/Components/QuestionCards/QuestionsForm";
import useStyles from "./BookedSession.styles";

// const initial_state = {
//   questionObj: {},
// };

export default function QuestionsFormModal(props) {
  const classes = useStyles();
  return (
    <ExlyModal
      title={props.title}
      open={props.isOpen}
      onPrimaryBtnClick={props.handleSubmit}
      onClose={props.closeModal}
      primaryBtnText={props.ctaText}
      modal_props={{
        modalPaperClassName: classes.modalPaperClass,
      }}
    >
      <QuestionsForms record={props?.record} />
    </ExlyModal>
  );
}
