import React from "react";
import MoneyField from "ui/modules/MoneyField";
import { listingTypeOptions } from "ui/pages/transactions/AllTransaction/AllTransactionTableConfig";
import {
  ActiveStatus,
  ContactDetails,
  LastActiveField,
  RoleType,
} from "../../components/TableFields/TableFields";
import {
  memberActiveStatusDropdownOptions,
  orgApiKeys,
  orgLabels,
  roleStatusMap,
  staffRoleDropdownOptions,
} from "../../Org.constants";
import { getCountInThousands } from "../../Org.utils";
import { ActionField } from "common/Components/TableFields/TableFields";

export const getTableConfig = ({ openMemberProfile }) => [
  {
    field: orgApiKeys.member_name,
    headerName: orgLabels.member_name,
    valueFormatter: (record) => (
      <ActionField
        label={record[orgApiKeys.member_name]}
        record={record}
        onClick={openMemberProfile}
      />
    ),
    isPrimary: true,
    avatar: true,
    sortable: false,
    fixed: "left",
    width: "200px",
    columnClassName: "word_break",
    ellipsis: true,
    maxCharCount: 25,
  },
  {
    field: orgApiKeys.role_type,
    headerName: orgLabels.role_type,
    valueFormatter: (record) => <RoleType record={record} />,
    sortable: false,
  },
  {
    field: orgApiKeys.is_active,
    headerName: orgLabels.is_active,
    valueFormatter: (record) => <ActiveStatus record={record} />,
    sortable: false,
    hidden: true,
  },
  {
    field: orgApiKeys.revenue,
    headerName: orgLabels.revenue,
    valueFormatter: (record) => (
      <MoneyField
        record={{ ...record, currency: null }}
        source={orgApiKeys.revenue}
      />
    ),
    sortable: false,
  },
  {
    field: orgApiKeys.customer_count,
    headerName: orgLabels.customer_count,
    valueFormatter: (record) =>
      getCountInThousands(record[orgApiKeys.customer_count]),
    sortable: false,
  },
  {
    field: orgApiKeys.last_active_time,
    headerName: orgLabels.last_active_time,
    valueFormatter: (record) => (
      <LastActiveField record={record} source={orgApiKeys.last_active_time} />
    ),
    sortable: false,
    noWrap: true,
  },
  {
    field: orgApiKeys.contact,
    headerName: orgLabels.contact,
    valueFormatter: (record) => <ContactDetails record={record} isDesktop />,
    hidden: true,
    sortable: false,
  },
];

export const filterConfig = [
  {
    source: orgApiKeys.role_filter,
    placeholder: orgLabels.role_filter,
    type: "dropdown",
    disableContains: true,
    options: staffRoleDropdownOptions,
  },
  {
    source: orgApiKeys.member_name_filter,
    placeholder: orgLabels.member_name_filter,
    type: "input",
    disableContains: true,
  },
  {
    source: orgApiKeys.email_filter,
    placeholder: orgLabels.email_filter,
    type: "input",
    disableContains: true,
  },
  {
    source: orgApiKeys.phone_number,
    placeholder: orgLabels.phone_number,
    type: "input",
    disableContains: true,
  },
  {
    source: orgApiKeys.offering_name,
    placeholder: orgLabels.offering_name,
    type: "input",
    disableContains: true,
  },
  {
    source: orgApiKeys.status_filter,
    placeholder: orgLabels.status_filter,
    type: "dropdown",
    disableContains: true,
    options: memberActiveStatusDropdownOptions,
  },
  {
    source: orgApiKeys.offering_type,
    placeholder: orgLabels.offering_type,
    type: "dropdown",
    disableContains: true,
    options: listingTypeOptions,
  },
];

export const tableChipsFormatter = (filter, value) => {
  switch (filter) {
    case orgApiKeys.role_filter:
      return `${orgLabels.role_filter}: ${roleStatusMap[value]?.label || ""}`;
    case orgApiKeys.member_name_filter:
      return `${orgLabels.member_name_filter}: ${value}`;
    case orgApiKeys.email_filter:
      return `${orgLabels.email_filter}: ${value}`;
    case orgApiKeys.phone_number:
      return `${orgLabels.phone_number}: ${value}`;
    case orgApiKeys.offering_name:
      return `${orgLabels.offering_name}: ${value}`;
    case orgApiKeys.status_filter: {
      const status = memberActiveStatusDropdownOptions?.find(
        (item) => item.value === value
      );
      return `${orgLabels.status_filter}: ${status?.label}`;
    }
    case orgApiKeys.offering_type: {
      const listing = listingTypeOptions?.find((item) => item.id === value);
      return `Offering Type: ${listing?.name}`;
    }
    default:
      return undefined;
  }
};
