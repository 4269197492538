import React from "react";

import ExlyModal from "common/Components/ExlyModal";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import WebsiteBannerImage from "assets/vectors/questions_success.svg";
import { useForm } from "react-final-form";
import { question_initial_state } from "schedule-v2/commonPages/Components/QuestionCards/questionFormConstants";

function AddQuestionsSuccess({ open, onClose, classes, openQuestionsModal }) {
  const form = useForm();

  const onAddQuestionModalOpen = () => {
    form.change("questionObj", { ...question_initial_state });
    openQuestionsModal();
  };

  return (
    <>
      <ExlyModal
        title={"Add Booking Question"}
        header={"Add Booking Question"}
        open={open}
        onClose={onClose}
        primaryBtnText={"Add Another Question"}
        onPrimaryBtnClick={onAddQuestionModalOpen}
        modal_props={{
          modalPaperClassName: classes?.modalPaperClass,
          backDropClickClose: true,
          primaryBtnFullWidth: false,
        }}
        mobile_modal_props={{}}
      >
        <div className={classes.modalBodyWrapper}>
          <div className={classes.websiteSuccessModalWrapper}>
            <span>Your question has been successfully added!</span>

            <div className={classes.assetWrapper}>
              <img
                src={WebsiteBannerImage}
                width={394}
                alt={"WebsiteBannerImage"}
              />
            </div>
          </div>
        </div>
      </ExlyModal>
    </>
  );
}

export default React.memo(withComponentLibraryTheme(AddQuestionsSuccess));
