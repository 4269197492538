import useAppLayoutControl from "hooks/useAppLayoutControl";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNotifications } from "utils/hooks";
import {
  getLeaderboardConfig,
  updateLeaderboardConfig,
} from "./Leaderboard.ApiCalls";
import {
  LEADERBOARD_LEARN_MORE_HREFS,
  LEADERBOARD_TABS,
} from "./leaderboard.data";
import { SETTINGS_FORM_KEYS } from "./components/pages/Settings/settings.data";

const useLeaderboard = () => {
  const isDesktop = useDesktopMediaQuery();
  const { listing_uuid: listingUuid } = useParams();

  const [settingsConfig, setSettingsConfig] = useState({});
  const [tab, setTab] = useState(LEADERBOARD_TABS.SCOREBOARD);
  const [loading, setLoading] = useState(false);

  const { notify } = useNotifications();

  const isSettingsTab = LEADERBOARD_TABS.SETTINGS === tab;
  const isScoreboardTab = LEADERBOARD_TABS.SCOREBOARD === tab;

  useAppLayoutControl({
    values: {
      title: "Leaderboard",
      showBottomNav: false,
      learnMoreHref: LEADERBOARD_LEARN_MORE_HREFS.LEADERBOARD,
    },
    control: {
      appBar: !isDesktop,
      bottomNav: !isDesktop,
      title: !isDesktop,
    },
  });

  useEffect(() => {
    if (isScoreboardTab) handleFetchConfig();
  }, [isScoreboardTab]);

  const handleFetchConfig = async () => {
    try {
      setLoading(true);
      const data = await getLeaderboardConfig(listingUuid);
      setSettingsConfig(data);
    } catch (error) {
      console.log("Error fetching leaderboard config", error);
      notify(
        "Something went wrong while fetching leaderboard settings.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleSaveSettings = async (formValues, form) => {
    try {
      await updateLeaderboardConfig({ listingUuid, ...formValues });
      form.reset(formValues);

      const {
        [SETTINGS_FORM_KEYS.isCustomerLeaderboardVisible]:
          isCustomerLeaderboardVisible,
      } = formValues;

      if (isCustomerLeaderboardVisible)
        notify(
          "We are building your score board, you will be notified once available.",
          "success"
        );
      else notify("Leaderboard settings saved successfully.", "success");
    } catch (error) {
      console.log("Error while saving leaderboard settings.", error);
      notify(
        "Something went wrong while saving leaderboard settings.",
        "error"
      );
    }
  };

  return {
    tab,
    isScoreboardTab,
    isSettingsTab,
    loading,
    settingsConfig,
    listingUuid,
    notify,
    handleFetchConfig,
    handleTabChange,
    handleSaveSettings,
  };
};

export default useLeaderboard;
