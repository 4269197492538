import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  ratings_wrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: ({ isDesktop }) => (isDesktop ? "row" : "row-reverse"),
    gap: "8px",
  },
  ratings_icon: {
    width: "31.21px",
    height: "31.21px",
    fill: ({ getColor, title, isDesktop }) =>
      isDesktop ? getColor?.(theme, title) : theme?.palette?.basic?.white,
  },
  ratings_title: ({ isDesktop }) =>
    isDesktop
      ? {
          color: theme?.palette?.secondary?.main,
          fontSize: "14px",
          lineHeight: "16px",
        }
      : {
          color: theme?.palette?.basic?.white,
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "20px",
        },
  remark_container: {
    paddingTop: "7.63px",
    paddingBottom: "8.81px",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 600, // TODO: in design its 500 but not looking bolder when using 500
  },
  remark_answer: {
    fontWeight: 400,
    marginTop: "8px",
  },
  remark_colored_answer: {
    color: ({ answer_color }) => answer_color,
  },
  empty_wrapper: ({ isDesktop }) =>
    isDesktop
      ? {
          paddingLeft: "94px",
          width: "500px",
          marginTop: "82px",
        }
      : {
          paddingLeft: "16px",
          width: "95%",
          paddingRight: "32px",
          marginTop: "48px",
        },
  empty_title: ({ isDesktop }) =>
    isDesktop
      ? {
          marginTop: "24.57px",
          marginBottom: "20px",
          color: "rgba(0, 0, 0, 0.85)",
          fontSize: "24px",
          lineHeight: "29px",
          fontWeight: 700,
        }
      : {
          marginTop: "24px",
          marginBottom: "16px",
          color: "rgba(0, 0, 0, 0.85)",
          fontSize: "18px",
          lineHeight: "22px",
          fontWeight: 700,
        },
  empty_content: {
    marginBottom: ({ isDesktop }) => (isDesktop ? "20px" : "16px"),
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: 400,
  },
  rating_stars: {
    display: "flex",
    justifyContent: "center",
  },
}));
