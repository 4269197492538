// Third party imports
import React from "react";

// Utils and providers
import { DateConvert } from "utils/Utils";
import { is_empty } from "utils/validations";

// Components
import { StatusField } from "./components/TableFields/TableFields";

export const msg_text =
  "Hey, I just wanted to gift you a 14 days long free trial of Exly which offers a one-stop solution to launch, manage and grow your business online. Click on this link: ";

export const form_data = {
  status: "Status",
  total_earned: "Commission Earned",
  referee_signup_date: "Date of Joining",
  referee_email: "Email",
};

const  statusOptions = {
  1: "Not Signed Up",
  2: "Signed Up",
  3: "Converted"
}

export const tableConfig = [
  {
    field: "referee_email",
    headerName: form_data.referee_email,
    avatar: true,
    isPrimary: true,
    emptyField: "N/A",
    sortable: false,
    columnClassName: "word_break",
    width: "400px"
  },
  {
    field: "referee_signup_date",
    headerName: form_data.referee_signup_date,
    valueFormatter: (record) =>
      is_empty(record.referee_signup_date)
        ? "N/A"
        : DateConvert(record, "referee_signup_date"),
    sortable: false,
  },
  {
    field: "total_earned",
    headerName: form_data.total_earned,
    emptyField: "N/A",
    sortable: false,    
  },
  {
    field: "status",
    headerName: form_data.status,
    valueFormatter: (record) => <StatusField record={record} source="status" />,
    align: "right",
    sortable: false,
    hidden: true,
  }
];

export const filterConfig = [
  {
    source: "referee_email",
    placeholder: "Email",
    type: "input",
  },
  {
    source: "referee_status",
    placeholder: "All Status",
    type: "dropdown",
    options: Object.keys(statusOptions).map(key => ({label: statusOptions[key], value: key}))
  },
];

export const tableChipsFormatter = (filter, value) => {
  switch (filter) {
    case "referee_email__icontains":
      return `Email: ${value}`;
    case "referee_status":
      return `Status: ${statusOptions[value]}`;
    default:
      return undefined;
  }
};
