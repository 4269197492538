import OwnerIcon from "assets/vectors/owner.svg";
import ManagerIcon from "assets/vectors/manager.svg";
import SupportTeamIcon from "assets/vectors/support.svg";
import PrimaryStaffIcon from "assets/vectors/primary-staff.svg";
import SecondaryStaffIcon from "assets/vectors/secondary-staff.svg";
import TertiaryStaffIcon from "assets/vectors/tertiary-staff.svg";
import constants from "constants/constants";
import { is_empty } from "utils/validations";
import permissionsConfig from "./PermissionsConfig";
import { getBrowserTimezoneOption } from "utils/dateTimeUtils";
import { isInternationalTimezone } from "utils/AuthUtil";

export const getMemberInitialValues = (role) => {
  const isInternational = isInternationalTimezone();
  const timezone = getBrowserTimezoneOption();

  return {
    first_name: "",
    last_name: "",
    email: "",
    country_code: isInternational
      ? constants.us_country_code
      : constants.indian_country_code,
    country: isInternational
      ? constants.us_country_name
      : constants.india_country_name,
    phone_number: "",
    display_image: null,
    social_info: {
      instagram: "",
      facebook_name: "",
      twitter: "",
      staff_heading: "",
      staff_description: "",
    },
    timezone,
    is_host_visible: true,
    is_lead_auto_assignment_allowed: false,
    role: role,
    // local states not going to backend
    phone_number_full: "",
    form_role_updated: false,
    heading: null,
    form_mode: "create",
  };
};

export const getSelectRoleOptions = (currentUserRole) => [
  {
    icon: OwnerIcon,
    title: "The owner",
    description: "Unrestricted access to all modules.",
    value: constants.STAFF_ROLES.OWNER,
    visible: currentUserRole < constants.STAFF_ROLES.OWNER,
  },
  {
    icon: ManagerIcon,
    title: "The Manager",
    description:
      "Access to all modules except reports, settings, and accountant.",
    value: constants.STAFF_ROLES.MANAGER,
    visible: currentUserRole < constants.STAFF_ROLES.MANAGER,
  },
  {
    icon: SupportTeamIcon,
    title: "Support Team",
    description:
      "Have someone who manages the services and customer side of things? Give that member the support team access.",
    value: constants.STAFF_ROLES.SUPPORT,
    visible: currentUserRole < constants.STAFF_ROLES.SUPPORT,
  },
  {
    icon: PrimaryStaffIcon,
    title: "Primary Staff",
    description:
      "Give your staff the freedom to view, and manage their own offerings, bookings, customers, and transactions, through this access type.",
    value: constants.STAFF_ROLES.PRIMARY_STAFF,
    visible: currentUserRole < constants.STAFF_ROLES.PRIMARY_STAFF,
  },
  {
    icon: SecondaryStaffIcon,
    title: "Secondary Staff",
    description:
      "No editing, No creation, Only view? This would be the best choice for you.",
    value: constants.STAFF_ROLES.SECONDARY_STAFF,
    visible: currentUserRole < constants.STAFF_ROLES.SECONDARY_STAFF,
  },
  {
    icon: TertiaryStaffIcon,
    title: "Tertiary Staff",
    description:
      "This access type would allow the member to only view their offerings and nothing else on their creator tool.",
    value: constants.STAFF_ROLES.TERTIARY_STAFF,
    visible: currentUserRole < constants.STAFF_ROLES.TERTIARY_STAFF,
  },
];

export const getCountInThousands = (val) => {
  const value = Number(val);
  if (is_empty(value) || isNaN(value)) return "N/A";
  if (value < 999) return value;
  return `${Math.floor(value / 1000)}k`;
};

export const getPermissionPayload = (permissions) => {
  const result = {};
  const filteredPermissionConfig = permissionsConfig.filter(
    (config) => permissions[config.source]
  );

  filteredPermissionConfig.forEach((item) => {
    if (item.isFeature) {
      result[item.source] = { ...permissions[item.source][item.source] };
    } else {
      item.features
        .filter((feat) => permissions[item.source][feat.source])
        .forEach((feat) => {
          result[feat.source] = { ...permissions[item.source][feat.source] };
        });
    }
  });

  return result;
};
