import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  footer: {
    paddingRight: "12px",
    justifyContent: "space-between",
  },
  learnMoreButton: {
    textDecoration: "underline",
  },
  modalPaperClass: {
    minWidth: "680px !important",
  },
  websiteSuccessModalWrapper: {
    margin: "auto ",
    display: "flex",
    marginTop: ({ isDesktop }) => (isDesktop ? "24px" : "18px"),
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "685px",
    width: "100%",
    fontWeight: "700 !important",
    font: ({ isDesktop }) =>
      isDesktop ? "22px Helvetica Neue,roboto" : "16px Helvetica Neue,roboto",
  },
  assetWrapper: {
    width: "340px",
    display: "flex",
    "& svg": {
      width: "340px",
    },
    marginTop: "15px",
    marginBottom: "20px",
  },

  nav_wrap_customer_list: {
    padding: "20px 24px",
    borderBottom: "1.5px solid #e7e6e4",
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },

  nav_back_btn: {
    "& svg": {
      color: "#272522",
      padding: "0 !important",
    },
  },

  page_title: {
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#272522",
  },
});
export default useStyles;
