import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    roleType: {
      fontSize: "14px",
      lineHeight: "22px",
      color: theme.palette.basic.white,
      padding: "0 8px",
      borderRadius: "2px",
      display: "inline-block",
    },
    color_fine_pine: {
      color: theme.palette.fine_pine.main,
    },
    color_coral_red: {
      color: theme.palette.coral_red.main,
    },
    onlineWrap: {
      display: "flex",
      gap: "4px",
      alignItems: "center",
    },
    onlineCircle: {
      background: theme.palette.fine_pine.main,
      width: "14px",
      height: "14px",
      borderRadius: "50%",
    },
    contactWrap: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
    },
    whatsappWrapper: {
      background: "rgba(79, 204, 93, 0.3)",
      width: "32px",
      height: "32px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "5px",
      "& img": {
        width: "18px",
        height: "18px",
      },
    },
    emailDesktopWrap: {
      padding: 0,
      width: "32px",
      height: "32px",
      borderRadius: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#EEECF9",
    },
    emailMobileWrap: {
      padding: 0,
    },
    emailIcon: {
      fill: "#493AB1",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
