import React from "react";
import OrganisationDesktop from "assets/images/featurePages/organisationDesktop.png";
import OrganisationMobile from "assets/vectors/featurePages/orgMobile.svg";
import OrganisationHome from "./OrgMembers";
import { hasCreatorPermissions } from "utils/AuthUtil";
import FeaturePage from "ui/pages/FeaturePage";
import { orgLabels } from "../../Org.constants";
import { RESOURCE_KEYS } from "utils/OrgPermissions";

const Organisation = (props) => {
  return (
    <>
      {hasCreatorPermissions(RESOURCE_KEYS.SECTIONS.ORGANIZATION) ? (
        <OrganisationHome {...props} />
      ) : (
        <FeaturePage
          featureName={RESOURCE_KEYS.SECTIONS.ORGANIZATION}
          desktopImage={OrganisationDesktop}
          mobileImage={OrganisationMobile}
          pageTitle={orgLabels.page_title}
        />
      )}
    </>
  );
};

export default Organisation;
