import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: ({ is_desktop }) => (is_desktop ? "auto" : "calc(100vw - 32px)"),
  },
  title: {
    fontSize: "18px",
    color: theme?.palette?.secondary?.main,
    marginBottom: "16px",
    letterSpacing: "0.04em",
    fontWeight: 700,
    lineHeight: "21px",
  },
  sliderBtn: {
    padding: 0,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 1,
    color: theme?.palette?.primary?.main,
    "& svg": {
      fontSize: "36.45px",
    },
  },
  sliderBackBtn: {
    left: 0,
  },
  sliderNextBtn: {
    right: 0,
  },
  swipeableViews: {
    alignItems: "center",
  },
  sliderContainer: {
    position: "relative",
    marginTop: "20px",
  },
  summaryContainer: {
    display: "flex",
    gap: "29px",
  },
  swipeableContainer: {
    overflowX: "auto",
    overflowY: "hidden",
    "-ms-scroll-snap-coordinate": "0 0",
    scrollSnapCoordinate: "0 0",
    "-ms-scroll-snap-pointsX": "repeat(100%)",
    scrollSnapPointsX: "repeat(100%)",
    "-ms-scroll-snap-type": "x mandatory",
    scrollSnapType: "x mandatory",
    flex: 1,
    display: "flex",
    gap: "20px",
    "-webkit-overflow-scrolling": "touch",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none" /* IE and Edge */,
    scrollbarWidth: "none",
  },
  ratingStars: {
    margin: "auto",
  },
  slickDots: {
    justifyContent: "flex-end",
    marginBottom: "20px",
  },
  starLabel: {
    color: theme?.palette?.fine_pine?.main,
    fontSize: "40px",
    fontWeight: 500,
    textAlign: "center",
  },
  star: {
    fontSize: "48px",
    color: theme?.palette?.fine_pine?.main,
  },
  description: {
    fontSize: "16px",
    color: theme?.palette?.secondary?.main,
    lineHeight: "21px",
  },
  circleText: {
    color: theme?.palette?.secondary?.main,
    fontSize: "14px",
    textAlign: "center",
    lineHeight: "17px",
  },
  contentClassName: {
    justifyContent: "center",
    gap: ({ is_desktop }) =>
      is_desktop ? "28px !important" : "16px !important",
    padding: ({ is_desktop }) => !is_desktop && "0px !important",
  },
  circlePercentage: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: -1,
    fontSize: "34px",
    fontWeight: 500,
    lineHeight: 42,
    color: theme?.palette?.secondary?.main,
  },
}));
