// Third party imports
import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Button } from "@my-scoot/component-library-legacy";
import classnames from "classnames";

// Styles and Icons
import useStyles from "./ShareReferrals.styles";
import style from "../../Style.module.css";
import Email from "../../../../../assets/vectors/social/emailRounded.svg";
import WhatsApp from "../../../../../assets/vectors/social/whatsapp.svg";
import Twitter from "../../../../../assets/vectors/social/twitterRounded.svg";
import Facebook from "../../../../../assets/vectors/social/facebookRounded.svg";

// Utils and providers
import constants from "constants/constants";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { msg_text } from "../../ReferralsTableConfig";
import { is_empty } from "utils/validations";
import { useNotifications } from "utils/hooks";
import { appendUrlParams } from "utils/urlUtils";

// Main Component
const ShareReferrals = ({ link, sharedLink, openModal }) => {
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const classes = useStyles({ isDesktop });
  const { pushNotification } = useNotifications();

  const handleCopy = () => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(link);
      pushNotification("Link Copied!", {
        variant: "outlined",
        color: "primary",
      });
    }
  };

  const twitterUrl = `https://twitter.com/intent/tweet?text=${`${msg_text} ${sharedLink}`}`;
  const whatsappUrl = `https://api.whatsapp.com/send?&text=${`${msg_text} ${sharedLink}`}`;
  const facebookUrl = appendUrlParams(constants.facebook_share_url, {
    link: sharedLink,
    quote: `${msg_text} ${sharedLink}`,
  });

  const socialImages = (
    <>
      <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
        <img src={Twitter} alt="Twitter" className={style.social_media_icon} />
      </a>
      <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
        <img
          src={Facebook}
          alt="Facebook"
          className={style.social_media_icon}
        />
      </a>
      <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
        <img
          src={WhatsApp}
          alt="WhatsApp"
          className={style.social_media_icon}
        />
      </a>
      <img
        src={Email}
        alt="email"
        className={style.social_media_icon}
        onClick={openModal}
      />
    </>
  );

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.heading}>Use referrals</div>
        <a
          href={constants.referral_link}
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          How does it work?
        </a>
      </div>
      <div className={classes.info_container}>
        <span className={classes.bold}>
          Use this link to refer your friends and earn while you sleep.
        </span>{" "}
        For every signup, you’ll get 50% of the revenue Exly makes from their
        business for 6 months.
      </div>

      {isDesktop ? (
        <div className={classes.social_container}>
          {link && (
            <div className={classes.copy_wrapper}>
              <div
                className={classnames(
                  classes.copy_link,
                  classes.copy_link_desktop
                )}
              >
                {link}
              </div>
              <Button
                size="small"
                onClick={handleCopy}
                className={classes.copy_button}
              >
                Copy
              </Button>
            </div>
          )}
          {!is_empty(sharedLink) && (
            <>
              <div>OR</div>
              <div className={classes.social_images_wrap_desktop}>
                {socialImages}
              </div>
            </>
          )}
        </div>
      ) : (
        <div>
          {link && (
            <div className={classes.copy_wrapper_mobile}>
              <div
                className={classnames(
                  classes.copy_link,
                  classes.copy_link_mobile
                )}
              >
                {link}
              </div>
              <Button
                size="small"
                onClick={handleCopy}
                className={classes.copy_button}
              >
                Copy
              </Button>
            </div>
          )}
          {!is_empty(sharedLink) && (
            <div className={classes.social_images_wrap_mobile}>
              {socialImages}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withComponentLibraryTheme(ShareReferrals);
