import { api } from "data";
import { TABS } from "../data/module_constants";

export const tableChipsFormatter = (ratings, listings) => (filter, value) => {
  switch (filter) {
    case "rating": {
      const rating = ratings?.rating.find((rating) => rating.id == value);
      return `Rating: ${rating?.title}`;
    }
    case "listing__title__icontains":
    case "title__icontains": {
      const offering = listings?.find((offering) => offering.title == value);
      return offering ? `Offering: ${offering?.title}` : null;
    }
    default:
      return undefined;
  }
};

export const getTabConfig = (filter = "") => {
  return [
    {
      label: TABS["user-feedbacks"].label,
      value: TABS["user-feedbacks"].value,
      path: `/${api.feedbacks.fetch_feedbacks_list}${filter}`,
    },
    {
      label: TABS["manage-feedback-forms"].label,
      value: TABS["manage-feedback-forms"].value,
      path: `/${api.feedbacks.fetch_listing_feedbacks}${filter}`,
    },
    {
      label: TABS["published-feedbacks"].label,
      value: TABS["published-feedbacks"].value,
      path: `/${api.feedbacks.fetch_published_feedbacks}${filter}`,
    },
  ];
};
