// Third party imports
import React from "react";
import { useListController } from "react-admin";
import classnames from "classnames";

// Styles and icons
import module_style from "../../Style.module.css";

// Utils and providers
import api from "../../../../../data/APIs";
import { getFilterConfig, getTableConfig } from "./ExlyFeedbacksTableConfig";

// Components
import ExlyLoader from "../../../../modules/ExlyLoader";
import ExlyTable from "../../../../../common/Components/ExlyTable";
import { RatingField } from "../../components/TableFields/TableFields";

import {} from "@my-scoot/component-library-legacy";
import MoreActions from "../../components/MoreActions";
import ActionFieldCustom from "ui/Fields/ActionFieldCustom";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { tableChipsFormatter } from "../../common/tableConfig";
import { useFeedbacks } from "../../data/useFeedbacks";
import StatsComponent from "../../components/StatsComponent";
import { EXLY_FEEDBACKS_MORE_HREFS, TABS } from "../../data/module_constants";
import useTabsConfig from "../../data/useTabsConfig";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
import { EXLY_TABLE_EMPTY_STATES_VERSIONS } from "common/Components/ExlyTable/ExlyTableEmptyState/constants/ExlyTableEmptyState.constants";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";

// TODO: Empty State UI Pending
const FilteredEmptyState = () => {
  return (
    <div className="text-center py-5 text-muted">
      <div>No Feedback found!</div>
    </div>
  );
};

const ExlyFeedbacks = (props) => {
  const isDesktop = useIsDesktop();
  const { ratings, processing, fetchRatings } = useFeedbacks(props);
  const { tabConfig } = useTabsConfig({
    ...props,
    active_tab: TABS["user-feedbacks"].value,
  });
  const {
    isOnboardedPrevVal: isFeatureVisited,
    flagLoading: isFeatureFlagLoading,
    handleFeatureOnboardChange,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.KNOWLEDGE_BASE.FEEDBACKS,
    successCallback: (marked) => !marked && handleFeatureOnboardChange(true),
  });

  React.useEffect(() => {
    fetchRatings();
  }, []);

  const { loading } = useListController(props);

  if (processing || loading) return <ExlyLoader />;

  return (
    <ExlyTable
      ra_props={props}
      tabConfig={tabConfig}
      selectedTab={TABS["user-feedbacks"].value}
      tableTitle="Feedback"
      columnConfig={getTableConfig(ratings)}
      tableFilters={getFilterConfig(ratings)}
      tableChipsFormatter={tableChipsFormatter(ratings)}
      filteredEmptyState={<FilteredEmptyState />}
      layoutProps={{
        paddingDesktop: "0",
        paddingBottom: "68px",
        layoutDesktopMargin: "20px 24px",
        noMobileBoxShadow: true,
        headerClassName: "mb-1",
        description: (
          <>
            Host events and receive feedback from your customers. All the
            collected feedback will be listed here.
            <StatsComponent />
          </>
        ),
        learnMoreHref: EXLY_FEEDBACKS_MORE_HREFS.FEEDBACK,
        secondaryComponent: isDesktop ? (
          <LearnMoreCta href={EXLY_FEEDBACKS_MORE_HREFS.FEEDBACK} />
        ) : null,
        descriptionClassName: "w-100",
      }}
      borderedFields={true}
      fieldsLeftPadded={true}
      fieldsAlignment="space-between"
      recordFooterVariant="secondary"
      getRecordFooterClassName={(record) =>
        classnames(
          module_style.mobile_footer,
          record.rating && module_style[`mobile_footer_${record.rating}`]
        )
      }
      renderSecondaryAction={(record) => (
        <div className="row no-gutters align-items-center">
          <span className={`mr-2 ${module_style.mobile_secondary_action}`}>
            Rating:
          </span>
          <RatingField record={record} source="rating" ratings={ratings} />
        </div>
      )}
      customPrimaryAction={{
        renderCustomAction: (record) => (
          <ActionFieldCustom
            show_more
            record={record}
            sortable={false}
            emptyText={"N/A"}
            ActionField={MoreActions}
            Nobutton={true}
          />
        ),
      }}
      primaryKey="id"
      noExportButton
      emptyStateProps={{
        isLoading: isFeatureFlagLoading,
        version: EXLY_TABLE_EMPTY_STATES_VERSIONS.V2,
        title: "No Feedback Received Yet.",
        description:
          "As soon as we have a new feedback, it will show up here. View the video to learn more.",
        videoSrc: EXLY_FEEDBACKS_MORE_HREFS.FEEDBACK_VIDEO,
        playerProps: { playing: !isFeatureFlagLoading && !isFeatureVisited }, // autoplay the knowledge base video on the first visit to this feature
        primaryCtaProps: {
          title: "Manage Feedback Form",
          onClick: () =>
            (window.location.href = `${window.location.origin}/#/${api.feedbacks.fetch_listing_feedbacks}`),
        },
        secondaryCtaProps: {
          variant: EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
          learnMoreHref: EXLY_FEEDBACKS_MORE_HREFS.FEEDBACK,
        },
        className: "mt-3",
      }}
    />
  );
};

export default withComponentLibraryTheme(ExlyFeedbacks);
