import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import { is_desktop } from '../../../utils/Utils';
import style from "./Style.module.css";
import { Dialog, Slide } from '@material-ui/core';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const CustomModal = ({open, openModal, children}) => {
    return (
        <>
            {is_desktop 
            ? (
                <Modal
                open={open}
                onClose={() => openModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                    <Box className={style.infoModalBox}>
                        <div className={style.infoModalHeader}>
                            <div className={style.infoModalHeaderTitle}>
                                Share your Exly referral sign-up<br />link with other creators
                            </div>
                            <div className={style.infoModalHeaderCloseBtn}>
                                <CloseIcon onClick={() => openModal(false)} style={{ fontSize: '15px' }} />
                            </div>
                        </div>
                        {children}
                    </Box>
                </Modal>
            )
            : (
                <Dialog
                open={open}
                TransitionComponent={Transition}
                // keepMounted
                PaperProps={{
                  style: {
                    width: '100%',
                    borderRadius: '20px 20px 0px 0px',
                    margin: '0px',
                    bottom: '0px',
                    position: 'absolute',
                    height: '30vh',
                    maxWidth: '780px',
                    padding: '15px'
                  },
                }}
                onClose={() => openModal(false)}
                aria-describedby="alert-dialog-slide-description"
                >
                    <div className={style.infoModalHeader}>
                        <div className={style.infoModalHeaderTitle}>
                            Share your Exly referral sign-up<br />link with other creators
                        </div>
                        <div className={style.infoModalHeaderCloseBtn}>
                            <CloseIcon onClick={() => openModal(false)} style={{ fontSize: '15px' }} />
                        </div>
                    </div>
                    {children}
                </Dialog>
            )}
        </>
    );
};

export default React.memo(CustomModal);

CustomModal.propTypes = {
    open: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    children: PropTypes.instanceOf(Object).isRequired,
};