// Third party imports
import React from "react";
import Rating from "react-rating";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

// Styles and icons
import EmptyStar from "@material-ui/icons/StarBorderRounded";
import StarsRoundedIcon from "@material-ui/icons/StarRounded";
import style from "../../../../../Style.module.css";

// Utils and providers
import { is_empty } from "../../../../../utils/validations";

import { Box } from "@material-ui/core";
import { useStyles } from "./FeedbackPieCharts.styles";

import RatingSlider from "../RatingSlider/RatingSlider";
import SliderItem from "../SliderItem";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";

const FeedbackPieCharts = ({
  ratings,
  overall_rating,
  bookings_feedbacks_chart = [],
  bookings_feedback_text,
  bookings_feedback_percentage,
}) => {
  const is_desktop = useDesktopMediaQuery();

  const classes = useStyles({ is_desktop });

  const CustomTooltip = ({ payload }) => {
    if (
      !is_empty(payload) &&
      !is_empty(payload[0]) &&
      !is_empty(payload[0].payload) &&
      !is_empty(payload[0].payload.tooltip)
    )
      return (
        <div className={`${style.chart_tooltip}`}>
          {payload[0].payload.tooltip}
        </div>
      );

    return null;
  };

  const RatingStats = (
    <div className={classes.ratingStars}>
      <Rating
        placeholderRating={overall_rating}
        readonly
        emptySymbol={<EmptyStar className={classes.star} />}
        placeholderSymbol={<StarsRoundedIcon className={classes.star} />}
      />
      <div className={classes.starLabel}>{overall_rating} / 5</div>
    </div>
  );

  const CircleStats = (
    <>
      <div style={{ height: 188, position: "relative" }}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={400} height={400}>
            <Pie
              data={bookings_feedbacks_chart}
              startAngle={360}
              endAngle={0}
              innerRadius={77.13}
              outerRadius={94}
              fill="#8884d8"
              paddingAngle={1}
              dataKey="value"
            >
              {bookings_feedbacks_chart.map((entry, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip
              wrapperStyle={{ maxWidth: "80%" }}
              content={<CustomTooltip />}
            />
          </PieChart>
        </ResponsiveContainer>
        <b className={classes.circlePercentage}>
          {bookings_feedback_percentage}%
        </b>
      </div>
      <div className={classes.circleText}>{bookings_feedback_text}</div>
    </>
  );

  return (
    <div className={classes.root}>
      {is_empty(overall_rating) ? (
        <></>
      ) : (
        <div className={classes.sliderContainer}>
          <Box className={classes.swipeableContainer}>
            <RatingSlider ratings={ratings} />
            <SliderItem label="Overall Rating">{RatingStats}</SliderItem>
            <SliderItem
              contentClassName={classes.contentClassName}
              label="Feedback Received/Total bookings"
            >
              {CircleStats}
            </SliderItem>
          </Box>
        </div>
      )}
    </div>
  );
};

export default FeedbackPieCharts;
