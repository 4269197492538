import React from "react";

import { IconButton } from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBackRounded";

import { Button } from "@my-scoot/component-library-legacy";

import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import styles from "./header.module.css";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { LEADERBOARD_LEARN_MORE_HREFS } from "../../leaderboard.data";

const Header = ({ onClick, showCta, loading }) => {
  const isDesktop = useDesktopMediaQuery();

  const ButtonComponent = (
    <Button
      onClick={onClick}
      buttonWrapperClassName={styles.buttonWrapperClassName}
      fullWidth={!isDesktop}
      loading={loading}
    >
      Save changes
    </Button>
  );

  return isDesktop ? (
    <div className={styles.root}>
      <div className={styles.titleContainer}>
        <IconButton onClick={() => window.history.go(-1)}>
          <BackIcon />
        </IconButton>
        Leaderboard
      </div>

      <div>
        <LearnMoreCta href={LEADERBOARD_LEARN_MORE_HREFS.LEADERBOARD} />{" "}
        {showCta && ButtonComponent}
      </div>
    </div>
  ) : (
    showCta && <div className={styles.mobileCtaWrapper}>{ButtonComponent}</div>
  );
};

export default Header;
