import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import React from "react";
import { is_empty } from "utils/validations";
import { bookings_feedbacks_chart } from "../data/module_constants";
import { useFeedbacks } from "../data/useFeedbacks";
import FeedbackPieCharts from "./FeedbackSummaryCharts/FeedbackPieCharts";

const StatsComponent = (props) => {
  const {
    fetchRatings,
    ratings,
    overall_rating,
    bookings_feedback_text,
    bookings_feedback_percentage,
  } = useFeedbacks(props);

  React.useEffect(() => {
    fetchRatings();
  }, []);

  if (is_empty(ratings)) {
    return <></>;
  }

  return (
    <>
      <FeedbackPieCharts
        ratings={ratings}
        overall_rating={overall_rating}
        bookings_feedbacks_chart={bookings_feedbacks_chart}
        bookings_feedback_text={bookings_feedback_text}
        bookings_feedback_percentage={bookings_feedback_percentage}
      />
    </>
  );
};

export default withComponentLibraryTheme(StatsComponent);
