import React, { useCallback, useState } from "react";
import MoreVert from "@material-ui/icons/MoreHoriz";
import module_style from "../Style.module.css";
import dataProvider from "data/dataProvider";
import api from "data/APIs";
import Popover from "@material-ui/core/Popover";
import { MobilePopover } from "@my-scoot/component-library-legacy";
import constants from "constants/constants";
import FeedbackDetails from "./FeedbackDetails.";
import { is_empty } from "utils/validations";
import { useNotifications } from "utils/hooks";
import { ArrowForwardIosRounded as ArrowIcon } from "@material-ui/icons";
import { useStyles } from "./MoreActions.styles";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { useFeedbacks } from "../data/useFeedbacks";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";

const MoreActions = (props) => {
  const { record, show_more } = props;
  const { getFeedbackQuestions, handleAddToOffering } = useFeedbacks(props);
  const classes = useStyles();

  const { pushNotification } = useNotifications();
  const isDesktop = useDesktopMediaQuery();
  const HAS_WRITE_ACCESS = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.FEEDBACKS
  );

  const [show_modal, set_show_modal] = React.useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = useCallback(
    (event) => setAnchorEl(event.currentTarget),
    [setAnchorEl]
  );
  const onClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  const feedback_questions = getFeedbackQuestions()?.data?.data;

  const shareTestimonial = async () => {
    try {
      let ratings = "\u2b50".repeat(parseInt(record.rating));
      let question = feedback_questions?.question_bank[record.rating][0];
      let answer = record.feedback_data.response.find(
        (item) => item.id == question.id
      );
      if (is_empty(answer))
        return pushNotification("No answer provided!", {
          variant: "outlined",
          color: "coral_red",
        });
      let content = `${ratings}${"\u000a"}${answer?.message}`;
      if (is_empty(content))
        return pushNotification(constants.error_message, {
          variant: "outlined",
          color: "coral_red",
        });

      let payload = {
        testimonial: {
          type: constants.testimonial_type.text,
          name: record.customer_name,
          content: content,
          media: "",
          cover_image: "",
          feedback_uid: record.uuid,
        },
      };

      const status = await dataProvider.custom_request(
        api.create_testimonial,
        "POST",
        payload
      );
      if (status.status === 200) {
        pushNotification(status.message, {
          variant: "outlined",
          color: "primary",
        });
        onClose();
      }
    } catch (err) {
      console.log("err", err);
      pushNotification(
        err?.body?.message ? err?.body?.message : constants.error_message,
        { variant: "outlined", color: "coral_red" }
      );
    }
  };

  const viewDetails = () => {
    onClose();
    set_show_modal(true);
  };

  // TODO: to be removed after trustmarker 3 release

  // const addToListing = async () => {
  //   try {
  //     let ratings = "\u2b50".repeat(parseInt(record.rating));
  //     let question = feedback_questions?.question_bank[record.rating][0];
  //     let answer = record.feedback_data.response.find(
  //       (item) => item.id == question.id
  //     );
  //     if (is_empty(answer))
  //       return pushNotification("No answer provided!", {
  //         variant: "outlined",
  //         color: "coral_red",
  //       });
  //     let content = `${ratings}${"\u000a"}${answer?.message}`;
  //     if (is_empty(content))
  //       return pushNotification(constants.error_message, {
  //         variant: "outlined",
  //         color: "coral_red",
  //       });

  //     let payload = {
  //       feedback_reviews: {
  //         name: record.customer_name,
  //         image: "",
  //         description: content,
  //         sub_heading: "",
  //       },
  //       listing_uuid: record.listing_uuid,
  //     };

  //     const status = await dataProvider.custom_request(
  //       api.add_listing_review,
  //       "POST",
  //       payload
  //     );
  //     if (status.status === 200) {
  //       pushNotification(status.message, {
  //         variant: "outlined",
  //         color: "primary",
  //       });
  //       onClose();
  //     }
  //   } catch (err) {
  //     console.log("err", err);
  //     pushNotification(
  //       err?.body?.message ? err?.body?.message : constants.error_message,
  //       { variant: "outlined", color: "coral_red" }
  //     );
  //   }
  // };

  const content = (
    <div className={`${module_style.week_modal}`}>
      <div className={module_style.item} onClick={shareTestimonial}>
        Share testimonial
      </div>
      <div className={module_style.item} onClick={viewDetails}>
        View details
      </div>
      {HAS_WRITE_ACCESS && (
        <div
          className={module_style.item}
          onClick={() => handleAddToOffering(record)}
        >
          Add to offering
        </div>
      )}
    </div>
  );

  return (
    <span style={{ position: "relative" }}>
      <div onClick={onOpen}>
        {show_more ? (
          <div className={classes.moreCta}>
            <span>More</span>
            <ArrowIcon />
          </div>
        ) : (
          <MoreVert aria-haspopup="true" className={"pointer"} />
        )}
      </div>
      {isDesktop ? (
        <Popover
          anchorEl={anchorEl}
          open={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          classes={{
            paper: module_style.popover_paper,
          }}
        >
          {content}
        </Popover>
      ) : (
        <MobilePopover open={anchorEl} onClose={onClose}>
          {content}
        </MobilePopover>
      )}

      {show_modal && (
        <FeedbackDetails
          selected_record={record}
          show_modal={show_modal}
          set_show_modal={(value) => set_show_modal(value)}
        />
      )}
    </span>
  );
};

export default MoreActions;
