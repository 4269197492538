// Third party imports
import React from "react";
import { is_empty } from "utils/validations";
import {
  RenderCustomerRating,
  RenderReviewStatus,
} from "../../components/TableFields/TableFields";
import { module_constants } from "../../data/module_constants";

export const getTableConfig = () => [
  {
    field: "listing_title",
    headerName: module_constants.listing_title,
    sortable: false,
    emptyField: "N/A",
  },
  {
    field: "customer_name",
    headerName: module_constants.customer_name,
    sortable: false,
    emptyField: "N/A",
    avatar: true,
    isPrimary: true,
  },
  {
    field: "rating",
    headerName: module_constants.customer_rating,
    valueFormatter: (record) => (
      <RenderCustomerRating record={record} source="rating" />
    ),
    sortable: false,
    hidden: true,
  },
  {
    field: "sub_heading",
    headerName: module_constants.caption,
    valueFormatter: (record) => (
      <div>{is_empty(record?.sub_heading) ? "" : record.sub_heading}</div>
    ),
    sortable: false,
    emptyField: "",
  },
  {
    field: "description",
    headerName: module_constants.feedback,
    sortable: false,
    emptyField: "N/A",
  },
  {
    field: "status",
    headerName: module_constants.status,
    sortable: false,
    hidden: true,
    emptyField: "N/A",
    valueFormatter: (record) => (
      <RenderReviewStatus record={record} source="status" />
    ),
  },
];

export const getFilterConfig = (filter_offerings) => {
  return [
    {
      source: "listing__title",
      placeholder: module_constants.listing_title,
      type: "input",
    },
    {
      source: "listing__uuid",
      placeholder: module_constants.listing,
      options: filter_offerings,
      type: "dropdown",
    },
  ];
};

export const tableChipsFormatter = (filter_offerings) => (filter, value) => {
  switch (filter) {
    case "listing__uuid": {
      const offering = filter_offerings?.find((item) => item.id == value);
      return offering ? `Offering: ${offering?.label}` : null;
    }
    case "listing__title__icontains":
      return `Offering title: ${value}`;
    default:
      return undefined;
  }
};
