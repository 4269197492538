import React from "react";
import { LinearProgress, withStyles } from "@material-ui/core";
import {
  MoodBad,
  SentimentVeryDissatisfied,
  SentimentSatisfied,
  SentimentVerySatisfied,
} from "@material-ui/icons";
import { useStyles } from "./Progress.styles";
import { module_constants } from "ui/pages/ExlyFeedbacks/data/module_constants";

const { getColor } = module_constants;

const kFormatter = (num) =>
  Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);

const Progress = ({ key, label, percentage, count }) => {
  const classes = useStyles({ getColor, label });

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: "10px",
      borderRadius: "5px",
      width: "100%",
      boxShadow: "0px 1px 1px rgba(0, 82, 204, 0.08)",
    },
    colorPrimary: {
      backgroundColor:
        theme?.palette?.grey[theme?.palette?.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: "5px",
      backgroundColor: getColor(theme, label),
    },
  }))(LinearProgress);

  return (
    <div key={key} className={classes.progressRoot}>
      <div className={classes.topContainer}>
        <div className={classes.titleContainer}>
          {
            {
              Horrible: <SentimentVeryDissatisfied />,
              Poor: <MoodBad />,
              Fine: <SentimentSatisfied />,
              Good: <SentimentVerySatisfied />,
              Excellent: <SentimentVerySatisfied />,
            }[label]
          }
          <span>{label}</span>
        </div>
        <span className={classes.tooltip}>
          {percentage}% ({kFormatter(count)})
        </span>
      </div>
      <BorderLinearProgress variant="determinate" value={percentage} />
    </div>
  );
};

export default Progress;
