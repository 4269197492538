import { api, dataProvider } from "data";

// fetch permissions of role
export const fetchRolePermissions = (role_type) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(api.organization.fetch_role_permissions, "GET", {
        role_value: role_type,
      })
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error(`Eror while fetching permissions of a role`);
        resolve(data.permissions);
      })
      .catch(reject);
  });

// fetch default permissions of role
export const fetchRoleDefaultPermissions = (role_type) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(api.organization.fetch_role_default_permissions, "GET", {
        role_value: role_type,
      })
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error(`Eror while fetching default permissions of a role`);
        resolve(data.permissions);
      })
      .catch(reject);
  });

// fetch permissions assigned to user
export const fetchUsersSavedPermissions = (org_uid) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(api.organization.fetch_users_saved_permissions, "GET", {
        org_uid,
      })
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error(`Eror while fetching saved permissions of a user`);
        resolve(data.permissions);
      })
      .catch(reject);
  });

// add staff member to org
export const addStaffMember = (payload) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(api.add_staff_member, "POST", payload)
      .then(resolve)
      .catch(reject);
  });

// update staff member details
export const updateStaffMemberDetails = (memberInfo, payload) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(
        `${api.update_staff}/${memberInfo.username}`,
        "POST",
        payload
      )
      .then(resolve)
      .catch(reject);
  });

// update permissions of staff member
export const updateStaffMemberPermissions = (payload) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(
        `${api.organization.update_member_permissions}`,
        "POST",
        payload
      )
      .then(resolve)
      .catch(reject);
  });

// delete member from organization
export const deleteMember = (memberInfo) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(`${api.delete_staff}/${memberInfo?.username}`, "POST")
      .then(resolve)
      .catch(reject);
  });

// fetch member details
export const fetchMemberDetails = (memberId) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(`${api.get_member_details}/${memberId}`, "GET")
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error(`Eror while fetching member details`);
        resolve(data);
      })
      .catch(reject);
  });

// fetch organization stats
export const fetchOrganizationStats = (memberId) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(`${api.get_organisation_stats}/${memberId}`, "GET")
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error(`Eror while fetching org member stats`);
        resolve(data);
      })
      .catch(reject);
  });

// fetch member upcoming schedule
export const fetchMemberUpcomingSchedule = (memberId) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(`${api.get_upcoming_schedules}/${memberId}`, "GET")
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error(`Eror while fetching member upcoming schedule`);
        resolve(data);
      })
      .catch(reject);
  });

// fetch member org logs
export const fetchMemberOrgLogs = (org_uuid, days) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(`${api.get_organisation_logs}`, "GET", {
        days,
        org_uuid,
      })
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error(`Eror while fetching member org logs`);
        resolve(data);
      })
      .catch(reject);
  });

// update permissions of org role
export const updateOrgRolePermissions = (payload) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(
        `${api.organization.update_role_permissions}`,
        "POST",
        payload
      )
      .then(resolve)
      .catch(reject);
  });

// assign listings to member
export const assignListingsToMember = (orgId, selectedListings) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(api.assign_listings, "POST", {
        org_uuid: orgId,
        listing_uuid: selectedListings,
      })
      .then(resolve)
      .catch(reject);
  });
