import React from "react";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import classnames from "classnames";
import CreateRoundedIcon from "@material-ui/icons/CreateRounded";

import { DateConvert } from "utils/Utils";
import { is_empty } from "utils/validations";
import { WhatsAppButton } from "webpage-leads/components/CustomColumns";
import { roleStatusMap } from "../../Org.constants";
import useStyles from "./TableFields.styles";
import { sendEmail } from "ui/Fields/EmailField";
import EmailIcon from "@material-ui/icons/Email";
import style from "../../Org.module.css";
import { ActionField } from "common/Components/TableFields/TableFields";

export const RoleType = ({ record }) => {
  const { role_type } = record || {};
  const classes = useStyles();
  if (is_empty(role_type) && role_type !== 0) return "N/A";
  const { label, background } = roleStatusMap[role_type] || {};

  return (
    <div className={classes.roleType} style={{ background }}>
      {label}
    </div>
  );
};

export const ActiveStatus = ({ record }) => {
  const classes = useStyles();
  const { active } = record || {};

  return active ? (
    <div className={classes.color_fine_pine}>Active</div>
  ) : (
    <div className={classes.color_coral_red}>Inactive</div>
  );
};

export const LastActiveField = ({ record, source }) => {
  const classes = useStyles();
  if (is_empty(record[source])) return "N/A";

  const now = moment();
  const then = moment(record[source]);
  const minutes = now.diff(then, "minutes");
  const isOnlineNow = minutes >= 0 && minutes <= 5;

  return isOnlineNow ? (
    <div className={classes.onlineWrap}>
      <div className={classes.onlineCircle} />
      <span>Online</span>
    </div>
  ) : (
    DateConvert(record, source)
  );
};

export const ContactDetails = ({ record, isDesktop }) => {
  const classes = useStyles();
  const { email, phone_number } = record || {};
  const isPhoneEmpty = is_empty(phone_number);
  const isEmailEmpty = is_empty(email);

  if (isPhoneEmpty && isEmailEmpty) return isDesktop ? "N/A" : null;

  return (
    <div className={classes.contactWrap}>
      {!isEmailEmpty && (
        <IconButton
          onClick={() => sendEmail(email)}
          className={
            isDesktop ? classes.emailDesktopWrap : classes.emailMobileWrap
          }
        >
          <EmailIcon className={classes.emailIcon} />
        </IconButton>
      )}
      {!isPhoneEmpty && (
        <WhatsAppButton
          record={record}
          source="phone_number"
          countryCodeKey="country_code"
          wrapperClassName={isDesktop ? classes.whatsappWrapper : ""}
        />
      )}
    </div>
  );
};

export const EditActionField = ({ record, onClick, color, className }) => {
  return (
    <IconButton
      className={classnames(style.padding0, className)}
      onClick={() => onClick({ record })}
    >
      <CreateRoundedIcon
        className={
          color === "primary" ? style.colorPrimaryMain : style.colorSecondary
        }
      />
    </IconButton>
  );
};

export const RoleNameField = ({ record, onClick = () => {} }) => {
  const { title, icon } = record || {};

  return (
    <div className="d-flex align-items-center">
      <img src={icon} alt={title} className={style.roleIcon} />
      <ActionField label={title} onClick={() => onClick({ record })} />
    </div>
  );
};
