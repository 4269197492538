// Third party imports
import React from 'react';
import classnames from 'classnames';

// Styles and icons
import useStyles from './TableFields.styles';

// Utils
import { is_empty } from 'utils/validations';


const referral_status = {
  NOT_SIGNED_UP: { name: "Not Signed Up", id: 0 },
  CONVERTED: { name: "Converted", id: 1 },
  Active: { name: "Active", id: 2 },
  SIGNED_UP: { name: "Signed Up", id: 3 },
};

export const StatusField = ({ record = {}, source }) => {
  const classes = useStyles();
  const status = record[source];
  if (is_empty(status)) return "N/A";

  return (
    <span className={classnames(classes.status, classes[`status__${status}`])}>
      {referral_status[status]?.name}
    </span>
  );
};

