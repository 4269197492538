/* eslint-disable react/no-array-index-key */
import React from "react";
import {
  Modal,
  ThemeProvider,
  creatorToolTheme,
  Input,
  Dropdown,
  MobileModal,
} from "@my-scoot/component-library-legacy";
import { is_empty } from "utils/validations";
import { module_constants } from "../data/module_constants";
import style from "../../../../Style.module.css";
import DeleteIcon from "@material-ui/icons/Delete";
import module_style from "../Style.module.css";
import dataProvider from "data/dataProvider";
import api from "data/APIs";
import ExlyLoader from "ui/modules/ExlyLoader";
import { is_desktop } from "utils/Utils";
import { useNotifications } from "utils/hooks";

const FeedbackForm = ({ show_modal, set_show_modal, selected_record }) => {
  const { pushNotification } = useNotifications();

  const [form_data, set_form_data] = React.useState({});

  React.useEffect(() => {
    (async () => {
      try {
        let temp = [
          {
            title: `How would you rate ${selected_record.listing_title} ?`,
            type: 4,
            choices: [],
            optional: false,
            default: true,
            is_active: true,
          },
        ];

        const feedback_listing_questions = await dataProvider.custom_request(
          api.feedbacks.fetch_feedback_questions,
          "GET",
          { listing_uuid: selected_record.listing_uuid }
        );
        if (feedback_listing_questions.status === 200) {
          let question_bank =
            feedback_listing_questions.data.data.question_bank;
          temp = temp.concat(question_bank["5"]);
          set_form_data({ data: temp });
        }
      } catch (err) {
        console.log("err Feedback form []", err);
      }
    })();
  }, []);

  const checkChoicesType = (type) => {
    return [2, 3].includes(type);
  };

  const handleUpdate = async () => {
    try {
      let temp = form_data.data.slice(1, form_data.data.length);
      for (let question of temp) {
        if (
          is_empty(question.title) ||
          is_empty(question.type) ||
          (checkChoicesType(question.type) &&
            question.choices.filter((item) => !is_empty(item)).length < 2)
        ) {
          return pushNotification("Invalid form values", {
            variant: "outlined",
            color: "coral_red",
          });
        }
      }
      const status = await dataProvider.custom_request(
        api.update_listing_feedbacks,
        "POST",
        { feedback_questions: temp, listing_uuid: selected_record.listing_uuid }
      );
      pushNotification(status.message, {
        variant: "outlined",
        color: "primary",
      });
      set_show_modal(false);
    } catch (err) {
      console.log("err handleUpdate", err);
      pushNotification(
        err?.body?.message ? err?.body?.message : "Something went wrong!",
        { variant: "outlined", color: "coral_red" }
      );
    }
  };

  const handleDelete = (index) => {
    let temp = form_data.data;
    temp[index].is_active = false;
    set_form_data({ ...{ data: temp } });
  };

  const handleAdd = () => {
    let temp = form_data.data;
    temp.push({
      title: ``,
      type: null,
      choices: [],
      optional: true,
      is_active: true,
    });
    set_form_data({ ...{ data: temp } });
  };

  const handleQuestionChange = ({ key, value, index }) => {
    let temp = form_data.data;
    temp[index][key] = value;
    if (key === "type" && checkChoicesType(value)) {
      temp[index].choices = ["", "", "", ""];
    }
    set_form_data({ ...{ data: temp } });
  };

  const renderForm = () => {
    return (
      <div className="pl-4 pr-4 my-2">
        <div className={``}>
          With Exly, you can efficiently collect feedback using the feedback
          forms. This enables you to understand your customers in a better way
          and thus grow as a creator.
        </div>

        {is_empty(form_data?.data) ? (
          <ExlyLoader />
        ) : (
          <div className={`mt-3`}>
            {form_data?.data?.map((item, i) => {
              if ("is_active" in item && !item.is_active) return <></>;
              return (
                <div
                  key={`question_${i}`}
                  className={`mb-3 row align-items-center`}
                >
                  <div className={`col-12 col-md-8 mb-1`}>
                    <span style={{ fontSize: 14, fontWeight: 600 }}>
                      Question
                    </span>{" "}
                    <span className={style.small_grey_text}>
                      ({item.default ? "Default" : "Editable"})
                    </span>
                    {!item.default && (
                      <span className={`ml-2`}>
                        <DeleteIcon
                          onClick={() => handleDelete(i)}
                          style={{ fill: "#BF2600", cursor: "pointer" }}
                        />
                      </span>
                    )}
                  </div>
                  {is_desktop && (
                    <div className={`col-12 col-md-4 mt-md-0 mt-1 mb-1`}>
                      <span style={{ fontSize: 14, fontWeight: 600 }}>
                        Answer type
                      </span>
                    </div>
                  )}
                  <div className={`col-12 col-md-8`}>
                    <Input
                      disabled={item.default}
                      value={item.title}
                      fullWidth
                      onChange={(e) =>
                        handleQuestionChange({
                          key: "title",
                          value: e.target.value,
                          index: i,
                        })
                      }
                      placeholder="Write your question...."
                      size="thin"
                    />
                  </div>
                  {!is_desktop && (
                    <div className={`col-12 col-md-4 mt-md-0 mt-1`}>
                      <span style={{ fontSize: 14, fontWeight: 600 }}>
                        Answer type
                      </span>
                    </div>
                  )}
                  <div className={`col-12 col-md-4 mt-md-0 mt-1`}>
                    <Dropdown
                      disabled={item.default}
                      fullWidth
                      placeholder="Select one"
                      value={item.type}
                      handleChange={(type) =>
                        handleQuestionChange({
                          key: "type",
                          value: type,
                          index: i,
                        })
                      }
                      options={module_constants.feedback_question_types}
                    />
                  </div>
                  {checkChoicesType(item.type) && (
                    <div className="col-12">
                      <div
                        className={`mt-1`}
                        style={{ fontSize: 14, fontWeight: 600 }}
                      >
                        Add Choices
                      </div>
                      <div className={`row mt-1`}>
                        {item.choices.map((choice, index) => {
                          return (
                            <div
                              key={`choice_${index}`}
                              className={`col-6 col-md-3 mt-md-0 mt-1`}
                            >
                              <Input
                                disabled={item.default}
                                value={choice}
                                fullWidth
                                onChange={(e) => {
                                  let temp = item.choices;
                                  temp[index] = e.target.value;
                                  handleQuestionChange({
                                    key: "choices",
                                    value: temp,
                                    index: i,
                                  });
                                }}
                                placeholder={`Option ${index + 1}`}
                                size="small"
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <ThemeProvider theme={creatorToolTheme}>
      {is_desktop ? (
        <Modal
          open={show_modal}
          modalPaperClassName={module_style.feedback_form_modal}
          title="Edit Feedback Form"
          primaryBtnText="Update Form"
          secondaryBtnText="Add More Question"
          onPrimaryBtnClick={handleUpdate}
          onSecondaryBtnClick={handleAdd}
          onClose={() => set_show_modal(false)}
        >
          <>{renderForm()}</>
        </Modal>
      ) : (
        <MobileModal
          open={show_modal}
          onClose={() => set_show_modal(false)}
          primaryAction={{
            label: "Update Form",
            onClick: handleUpdate,
          }}
          secondaryAction={{
            label: "Add More Question",
            onClick: handleAdd,
          }}
          header="Edit Feedback Form"
        >
          <>{renderForm()}</>
        </MobileModal>
      )}
    </ThemeProvider>
  );
};

export default FeedbackForm;
