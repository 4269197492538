import React from "react";
import classnames from "classnames";
import { Card, Button } from "@my-scoot/component-library-legacy";

import style from "../Org.module.css";
import { orgMemberCardDetails } from "../Org.constants";

const OrgMemberCards = ({ addMember }) => {
  return (
    <div className={`mx-auto ${style.noMembersPlaceholder}`}>
      {orgMemberCardDetails.map(({ icon, title, description, role }) => (
        <Card
          key={title}
          CardClassName={`d-flex p-4 flex-column text-center ${style.orgMemberCard}`}
        >
          <div className={`${style.displayIcon}`}>
            <img src={icon} alt={title} />
          </div>

          <h5
            className={classnames(
              style.colorPrimary,
              "mb-2 font_500",
              style.f_14
            )}
          >
            {title}
          </h5>
          <span className={classnames(style.colorSecondary, style.f_14)}>
            {description}
          </span>

          <Button
            color="secondary"
            fullWidth
            buttonWrapperClassName={style.addMemberBtn}
            onClick={() => addMember(role)}
          >
            Add Member
          </Button>
        </Card>
      ))}
    </div>
  );
};

export default OrgMemberCards;
