import {
    Pagination, useListController,
} from 'react-admin';
import React from 'react';
import style from '../../../Style.module.css';
import { isInteger, is_empty } from '../../../utils/validations';
import { is_desktop } from '../../../utils/Utils';
import EmptyList from '../../modules/EmptyList';
import constants from '../../../constants/constants';
import TextFieldCustom from '../../Fields/TextFieldCustom';
import ActionFieldCustom from '../../Fields/ActionFieldCustom';
import { CustomGrid } from '../../modules/CustomGrid';
import { getParameterByName } from '../../../utils/Utils';

let form_data = {
    member_name: 'Member name',
    staff: 'Staff type',
    action_section_name: 'Action section',
    action: 'Action',
}

const Logs = (props) => {

    const { filterValues, loaded, data } = useListController(props);
    const mapData = Object.values(data);

    const ListPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

    const staffType = ({ record, source }) => {
        return isInteger(record[source]) ? constants.staff_type[record[source]].name : 'N/A';
    }

    return (
        <div className={`${is_desktop ? 'parent' : ''} ${style.sticky}`}>
            <div className={`dm_font mobile_row ${style.mobileHeader}`}>
                <div className={`${style.flex_row} `}>
                    <span className={style.table_header} style={{ flex: 4 }}>Organisation Logs</span>
                </div>
            </div>



            {is_empty(data) && loaded && is_empty(props.location.search) && is_empty(filterValues) ? <EmptyList preview_image={constants.preview_content_purchases} secondary_cta="View sample data" module={'transaction'} module_text={'Log'} /> : <>{!is_empty(filterValues) || !is_empty(getParameterByName('custom_filter')) ?
                <div style={{ paddingLeft: 0 }} className={`${style.clear_filter} ${is_desktop ? "" : style.clearFilterMobile}`} onClick={() => {
                    window.location.href = `${window.location.origin}/#/users/fetch/org/logs`;
                    window.location.reload();
                }} ><span className='text-danger'>Clear Filter</span></div> : null}

                <div className={is_desktop ? `` : style.mobileParent}>
                    <CustomGrid mapData={mapData} props={props} ListPagination={ListPagination} exporter={false} PostFilter={false} listItem={style.listItem}>
                        <TextFieldCustom sortable={false} source="member_name" label={form_data.member_name} emptyText={'N/A'} />
                        <ActionFieldCustom sortable={false} label={form_data.staff} source="staff" emptyText={'N/A'} ActionField={staffType} Nobutton={true} />
                        <TextFieldCustom sortable={false} source="action_section_name" label={form_data.action_section_name} emptyText={'N/A'} />
                        <TextFieldCustom sortable={false} source="action" label={form_data.action} emptyText={'N/A'} />
                    </CustomGrid>
                </div>
            </>
            }
        </div>
    );
}

export default Logs;