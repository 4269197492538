import AngryIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfied from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfied from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAlt from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentVerySatisfied from "@material-ui/icons/SentimentVerySatisfied";

export const module_constants = {
  listing_title: "Offering Title",
  customer_name: "Customer name",
  customer_rating: "Customer rating",
  caption: "Caption",
  feedback: "Feedback",
  status: "Status",
  remark: "Remark",
  listing: "Offering",
  rating: "Rating",
  avg_rating: "Average Rating",
  feedback_count: "No. of feedback ",
  action: "Actions",
  user_feedbacks_tab: 1,
  manage_feedbacks_tab: 2,
  feedback_link: "Feedback form link ",
  feedback_question_types: [
    { label: "Text", value: 1, id: 1 },
    { label: "Single Select", value: 2, id: 2 },
    { label: "Multi Select", value: 3, id: 3 },
    { label: "Emoji Ratings", value: 4, id: 4 },
  ],
  getColor: (theme, label) =>
    ({
      Horrible: theme?.palette?.coral_red?.main,
      Poor: theme?.palette?.butterscotch_yellow?.main,
      Fine: "#0556CD",
      Good: theme?.palette?.fine_pine?.shade_800,
      Excellent: "#00B77A",
    }[label]),
};

export const rating_icons = [
  null,
  { icon: AngryIcon, title: "Horrible", value: 1, color: "#D12626" },
  { icon: SentimentDissatisfied, title: "Poor", value: 2, color: "#FF6B00" },
  { icon: SentimentSatisfied, title: "Fine", value: 3, color: "#F2CB00" },
  { icon: SentimentSatisfiedAlt, title: "Good", value: 4, color: "#0CBECACC" },
  {
    icon: SentimentVerySatisfied,
    title: "Excellent",
    value: 5,
    color: "#0C9B23CC",
  },
];

export const bookings_feedbacks_chart = [
  { name: "Bookings", value: 0, color: "#DF635D", tooltip: "" },
  { name: "Feedback", value: 0, color: "#00B779", tooltip: "" },
];

export const TABS = {
  "user-feedbacks": {
    label: "User feedback",
    value: "user-feedbacks",
  },
  "manage-feedback-forms": {
    label: "Manage feedback forms",
    value: "manage-feedback-forms",
  },
  "published-feedbacks": {
    label: "Published feedback",
    value: "published-feedbacks",
  },
};

export const published_feedbacks = {
  statuses: {
    1: {
      value: 1,
      color: "#BF2600",
      label: "Draft",
      backgroundColor: "#F7D6D4",
    },
    2: {
      value: 2,
      color: "#00A570",
      label: "Live",
      backgroundColor: "#E5FFF7",
    },
    3: {
      value: 2,
      color: "#F19400",
      label: "Hidden",
      backgroundColor: "#FFF7E6",
    },
  },
  add_custom_feedback: "Add custom feedback +",
};

export const EXLY_FEEDBACKS_MORE_HREFS = {
  FEEDBACK:
    "https://support.exlyapp.com/support/solutions/articles/84000356537-feedback-tool-on-exly",
  FEEDBACK_VIDEO: "https://www.youtube-nocookie.com/embed/iyWIHNSVAmY",
};
