import makeStyles from "@material-ui/styles/makeStyles";

export default makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "11px",
  },
  heading: {
    color: theme?.palette?.secondary?.main,
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: 600, // TODO: in design it is 400 but is bolder
  },
  link: {
    color: theme?.palette?.primary?.main,
    fontSize: "16px",
    fontWeight: 500,
    textDecoration: "underline",
  },
  info_container: {
    color: theme?.palette?.secondary?.main,
    marginBottom: "24px",
    fontSize: "16px",
    maxWidth: "757px",
  },
  bold: {
    fontWeight: 600, // TODO: in design is 500, but is bolder
  },
  social_container: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    marginBottom: "32px",
    flexWrap: "wrap",
  },
  copy_wrapper: {
    display: "flex",
    gap: "16px",
  },
  copy_wrapper_mobile: {
    width: "calc(100vw - 32px)",
    display: "flex",
    justifyContent: "space-between",
  },
  copy_link: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    borderRadius: "5px",
    border: `1.5px solid ${theme?.palette?.secondary?.shade_200}`,
    color: theme?.palette?.persian_blue?.main,
    fontSize: "16px",
    lineHeight: "16px",
    padding: "11px 16px 10px 12px",
  },
  copy_link_desktop: {
    width: "420px",
  },
  copy_link_mobile: {
    width: "calc(100vw - 32px - 90px - 12px)",
    marginBottom: "16px",
    display: "inline-block",
  },
  copy_button: {
    minWidth: "0",
    width: ({ isDesktop }) => (isDesktop ? "106px" : "90px"),
  },
  social_images_wrap_mobile: {
    marginBottom: "32px",
  },
  social_images_wrap_desktop: {
    display: "flex",
  },
}));
