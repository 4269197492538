// Third party imports
import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";

// Utils and poviders
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";

// Components
import StatsCard from "common/Components/StatsCard";

// Icons
import LeadsIcon from "assets/images/trafficAnalysisLeads.svg";
import MoneyIcon from "assets/vectors/money.svg";
import RateIcon from "assets/vectors/rate.svg";
import { getUserCurrencySymbol } from "utils/AuthUtil";
import { is_empty } from "utils/validations";
import { roundOff } from "utils/Utils";

// Styles
const useStyles = makeStyles(() => ({
  statsWrapper: ({ isDesktop }) =>
    isDesktop
      ? {
          display: "flex",
          marginBottom: "20px",
          gap: "26px",
        }
      : {
          display: "flex",
          gap: "10px",
          width: "calc(100vw - 32px)",
          overflowX: "scroll",
        },
  statsCard: ({ isDesktop }) =>
    isDesktop
      ? {}
      : {
          marginBottom: "16px",
        },
}));

const StatsLoadingState = ({ color }) => (
  <CircularProgress size={18} color={color} />
);

const ReferralStats = ({ referralOverview }) => {
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const classes = useStyles({ isDesktop });

  return (
    <Box className={classes.statsWrapper}>
      <StatsCard
        className={classes.statsCard}
        color="primary"
        linkText=""
        title="Total Referred Sign Ups"
        statsNumber={
          referralOverview ? (
            referralOverview?.total_referrals || "0"
          ) : (
            <StatsLoadingState color="primary" />
          )
        }
        Icon={(props) => <img src={LeadsIcon} {...props} />}
      />
      <StatsCard
        className={classes.statsCard}
        color="fine_pine"
        linkText=""
        title="Total Commissions Earned"
        statsNumber={
          referralOverview ? (
            `${getUserCurrencySymbol()}${
              !is_empty(referralOverview?.total_amount_earned)
                ? roundOff(referralOverview?.total_amount_earned, 2)
                : "0"
            }`
          ) : (
            <StatsLoadingState color="fine_pine" />
          )
        }
        Icon={(props) => <img src={MoneyIcon} {...props} />}
      />
      <StatsCard
        className={classes.statsCard}
        color="persian_blue"
        linkText=""
        title="Your Commission Rate"
        statsNumber={
          referralOverview ? (
            `${referralOverview?.commission_rate ?? 0}%`
          ) : (
            <StatsLoadingState color="persian_blue" />
          )
        }
        Icon={(props) => <img src={RateIcon} {...props} />}
      />
    </Box>
  );
};

export default withComponentLibraryTheme(ReferralStats);
