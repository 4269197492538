import React from "react";
import {
  Modal,
  ThemeProvider,
  creatorToolTheme,
} from "@my-scoot/component-library-legacy";
import style from "../../../../Style.module.css";
import module_style from "../Style.module.css";
import dataProvider from "data/dataProvider";
import api from "data/APIs";
import { is_empty } from "utils/validations";
import ExlyLoader from "ui/modules/ExlyLoader";
import { module_constants, rating_icons } from "../data/module_constants";
import { useFeedbacks } from "../data/useFeedbacks";

const FeedbackDetails = (props) => {
  const { show_modal, set_show_modal, selected_record } = props;
  const { getFeedbackQuestions } = useFeedbacks(props);

  const feedback_questions = getFeedbackQuestions();
  const ratings = feedback_questions?.data?.data?.rating;

  const [question_bank, set_question_bank] = React.useState([]);
  const [processing, set_processing] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      try {
        const feedback_listing_questions = await dataProvider.custom_request(
          api.feedbacks.fetch_feedback_questions,
          "GET",
          { listing_uuid: selected_record.listing_uuid }
        );
        if (feedback_listing_questions.status === 200) {
          set_processing(false);
          let question_bank =
            feedback_listing_questions.data.data.question_bank;
          let temp = question_bank[`${selected_record.rating}`];
          set_question_bank(temp);
        }
      } catch (err) {
        console.log("err Feedback form []", err);
        set_processing(false);
      }
    })();
  }, []);

  const renderReviews = () => {
    if (is_empty(question_bank)) return <div className={`mb-4`}>N/A</div>;
    if (is_empty(selected_record?.feedback_data?.response)) return "N/A";
    let no_remark_check = selected_record?.feedback_data?.response.filter(
      (item) => !is_empty(item.message)
    );
    if (is_empty(no_remark_check)) return "N/A";

    return (
      <>
        {question_bank.map((item) => {
          let response = selected_record.feedback_data?.response.filter(
            (answer) => answer.id == item.id
          );
          const Emoji =
            item.type == module_constants.feedback_question_types[3].value &&
            !is_empty(response[0]?.message)
              ? rating_icons.filter(
                  (emoji) => emoji && emoji.title == response[0].message
                )[0]
              : null;
          const RatingsIcon = Emoji ? Emoji.icon : null;

          if (!is_empty(response))
            return (
              <div className={`${module_style.remark_container}`}>
                <div className={`${style.small_grey_text}`}>{item.title}</div>
                <div className={`row no-gutters`}>
                  {RatingsIcon && (
                    <RatingsIcon
                      style={{ fill: Emoji.color, marginRight: 10 }}
                    />
                  )}
                  <div className={`${module_style.answer}`}>
                    {response[0].message}
                  </div>
                </div>
              </div>
            );
        })}
      </>
    );
  };

  const emoji = rating_icons.filter(
    (emoji) => emoji && emoji.value == selected_record.rating
  )[0];
  const RatingsIcon = emoji ? emoji.icon : null;

  return (
    <ThemeProvider theme={creatorToolTheme}>
      <Modal
        open={show_modal}
        modalPaperClassName={module_style.feedback_detail_modal}
        title="Feedback details"
        onClose={() => set_show_modal(false)}
        customFooter={<></>}
      >
        <div className={`pl-4 pr-4`}>
          <div className={`${module_style.detail_row}`}>
            <span className={`${module_style.detail_title}`}>
              Offering name
            </span>
            <span className={`${module_style.detail_description}`}>
              {selected_record.listing_title}
            </span>
          </div>
          <div className={`${module_style.detail_row}`}>
            <span className={`${module_style.detail_title}`}>
              Customer name
            </span>
            <span className={`${module_style.detail_description}`}>
              {selected_record.customer_name}
            </span>
          </div>
          <div className={`${module_style.detail_row}`}>
            <span className={`${module_style.detail_title}`}>Rating</span>
            <div>
              {RatingsIcon && (
                <RatingsIcon style={{ fill: emoji.color, marginRight: 10 }} />
              )}
              <span className={`${module_style.detail_description}`}>
                {
                  ratings.filter(
                    (item) => item.id === selected_record.rating
                  )[0].title
                }
              </span>
            </div>
          </div>

          {processing ? (
            <ExlyLoader />
          ) : (
            <>
              <div className={`${module_style.detail_title} mt-2 mb-2`}>
                Reviews
              </div>
              <div>{renderReviews()}</div>
            </>
          )}
        </div>
      </Modal>
    </ThemeProvider>
  );
};

export default FeedbackDetails;
