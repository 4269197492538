import React from "react";
import SliderItem from "../SliderItem";

import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import Progress from "./components/Progress";
import { useStyles } from "./RatingSlider.styles";

const RatingSlider = ({ ratings }) => {
  const classes = useStyles();

  const { rating: feedbackRatings } = ratings;

  const totalRatings = feedbackRatings.reduce(
    (prev, rating) => prev + rating.count,
    0
  );

  const getPercent = (count) => Math.round((count / totalRatings) * 100);

  return (
    <SliderItem
      label="Overall Feedback Breakdown"
      contentClassName={classes.content}
    >
      {feedbackRatings.map((feedbackRating) => {
        const { id, rating, title, tooltip, count } = feedbackRating;

        return (
          <Progress
            key={id}
            rating={rating}
            label={title}
            tooltip={tooltip}
            count={count}
            percentage={getPercent(count)}
          />
        );
      })}
    </SliderItem>
  );
};

export default withComponentLibraryTheme(RatingSlider);
