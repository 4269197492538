// Third party imports
import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
// Styles and icons
import style from "./Style.module.css";
import useStyles from "./components/TableFields/TableFields.styles";

// Utils and providers
import { is_desktop } from "../../../utils/Utils";
import { is_empty, isValidEmail } from "../../../utils/validations";
import api from "../../../data/APIs";
import dataProvider from "../../../data/dataProvider";
import { filterConfig, tableChipsFormatter, tableConfig } from "./ReferralsTableConfig";

// Components
import CustomModal from "./customModal";
import ExlyTable from "common/Components/ExlyTable";
import { StatusField } from "./components/TableFields/TableFields";
import ShareReferrals from "./components/ShareReferrals/ShareReferrals";
import ReferralStats from "./components/ReferralStats/ReferralStats";
import EmptyListIcon from "assets/vectors/infoModal/Presentation.svg";
import constants from "constants/constants";
import { useNotifications } from "utils/hooks";

const emptyStateProps = {
  page_title: "Your Referrals",
  content_title: "No referrals yet.",
  empty_list_icon: EmptyListIcon,
  alt: "no referrals",
  secondary_cta: { children: "View sample data" },
  preview_image: constants.preview_refer_a_friend,
};

// Main Component
const Referrals = (props) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const cid = query.get('cid');
  const [link, setLink] = useState(null);
  const [sharedLink, setSharedLink] = useState(null);
  const [referralOverview, setReferralOverview] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [textError, setTextError] = useState(false);

  const { pushNotification } = useNotifications();
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      try {
        const data = await dataProvider.custom_request(api.get_referral, "GET");
        if (data.status === 200) {
          setLink(data.data.referral_url);
          setSharedLink(
            `${window.location.origin}/%23/%3Fref=${data.data.referral_code}`
          );
        }
      } catch (err) {
        console.log(err);
      }
      try {
        const data = await dataProvider.custom_request(
          api.get_referral_overview,
          "GET"
        );
        if (data.status === 200) {
          setReferralOverview(data.data);
        } else {
          setReferralOverview(null);
        }
      } catch (err) {
        console.log(err);
      }
      if(cid) {
        try {
            const payload = {
                cid: cid,
                click_type: 0
            };
            const response = await dataProvider.custom_request(api.record_referral_clicks,
                    'POST',
                    payload
                );
        } catch(err) {
            console.log(err);
    }
    }
    })();
  }, []);

  const validateEmail = (value) => {
    setEmail(value);
    setTextError(true);
  };

  const handleEmail = async () => {
    try {
      let check_err = false;
      if (is_empty(email)) {
        check_err = true;
        setTextError(true);
      } else if (!isValidEmail(email)) {
        check_err = true;
        setTextError(true);
      }
      if (!check_err) {
        const status = await dataProvider.custom_request(
          api.send_referral_mail,
          "POST",
          { referee_email: email }
        );
        if (status.status === 200) {
          await setEmail("");
        }
        if (!is_empty(status?.message)) {
          pushNotification(status.message, { variant: "outlined", color: "primary" });
        }
      }
    } catch (err) {
      pushNotification(
        err?.body?.message
          ? err?.body?.message
          : "Some error occured while sending invite.",
          { variant: "outlined", color: "coral_red" }
      );
    }
  };

  return (
    <div className={style.container}>
      <ShareReferrals
        link={link}
        sharedLink={sharedLink}
        openModal={() => setShowModal(true)}
      />

      <ReferralStats referralOverview={referralOverview} />

      <ExlyTable
        ra_props={props}
        tableTitle="Your Referrals"
        columnConfig={tableConfig}
        tableFilters={filterConfig}
        tableChipsFormatter={tableChipsFormatter}
        layoutProps={{
          paddingDesktop: "0",
          paddingMobile: "0",
          paddingBottom: "68px",
          noMobileBoxShadow: true,
        }}
        borderedFields
        drawerFieldsBordered
        fieldsAlignment="space-between"
        drawerFieldsAlignment="space-between"
        recordFooterVariant="secondary"
        recordFooterClassName={classes.footer}
        getRecordFooterClassName={(record) =>
          record.status && classes[`footer__${record.status}`]
        }
        renderSecondaryAction={() => (
          <div className={classes.mobile_secondary_action}>Status:</div>
        )}
        customPrimaryAction={{
          renderCustomAction: (record) => (
            <StatusField record={record} source="status" />
          ),
        }}
        primaryKey="id"
        noExportButton
        {...emptyStateProps}
      />

      {showModal && (
        <CustomModal open={showModal} openModal={setShowModal}>
          <div className={style.label}>
            Email Address&nbsp;<span style={{ color: "red" }}>*</span>
          </div>
          {is_desktop ? (
            <div className="row">
              <div className="col-md-8 col-12" style={{ paddingRight: "0px" }}>
                <TextField
                  type="email"
                  variant="outlined"
                  size="small"
                  placeholder="Enter Email"
                  fullWidth
                  required
                  value={email}
                  onChange={(e) => validateEmail(e.target.value)}
                  InputProps={{
                    classes: {
                      root: textError ? style.textinputError : style.textinput,
                    },
                  }}
                />
              </div>
              <div className="col-md-4 col-12">
                <Button className={style.save_btn} onClick={handleEmail}>
                  Send Email
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <TextField
                type="email"
                variant="outlined"
                size="small"
                placeholder="Enter Email"
                fullWidth
                required
                value={email}
                onChange={(e) => validateEmail(e.target.value)}
                InputProps={{
                  classes: {
                    root: textError ? style.textinputError : style.textinput,
                  },
                }}
              />
              <Button className={style.save_btn} onClick={handleEmail}>
                Send Email
              </Button>
            </div>
          )}
        </CustomModal>
      )}
    </div>
  );
};

export default Referrals;
