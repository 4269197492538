import { dataProvider } from "data";
import { leaderboard_api } from "./Leaderboard.api";

export const getLeaderboardConfig = async (listingUuid) => {
  const { data, status } = await dataProvider.custom_request(
    leaderboard_api.get_leaderboard_config({ listingUuid })
  );
  if (status !== 200)
    throw new Error(`Error fetching leaderboard config: ${data} ${status}`);
  return data;
};

export const updateLeaderboardConfig = async ({
  listingUuid,
  ...restFormValues
}) => {
  const { data, status } = await dataProvider.custom_request(
    leaderboard_api.update_leaderboard_config,
    "POST",
    {
      listing_uuid: listingUuid,
      ...restFormValues,
    }
  );
  if (status !== 200)
    throw new Error(`Error updating leaderboard config: ${data} ${status}`);
  return data;
};
