export const BOOKING_QUESTIONS_LEARN_MORE_HREFS = {
  BOOKING_QUESTIONS:
    "https://support.exlyapp.com/en/support/solutions/articles/84000392258-how-to-add-booking-questions-to-an-offering-",
  BOOKING_QUESTIONS_VIDEO: "https://www.youtube-nocookie.com/embed/jY0thFLgYAM",
};

const constants = {
  form_data: {
    question: "question",
    type: "type",
  },

  form_header: {
    question: "Question",
    type: "Answer Type",
    action: "Action",
  },
};
export default constants;
