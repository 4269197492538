import OwnerIcon from "assets/vectors/owner.svg";
import ManagerIcon from "assets/vectors/manager.svg";
import SupportTeamIcon from "assets/vectors/support.svg";
import PrimaryStaffIcon from "assets/vectors/primary-staff.svg";
import SecondaryStaffIcon from "assets/vectors/secondary-staff.svg";
import TertiaryStaffIcon from "assets/vectors/tertiary-staff.svg";
import constants from "constants/constants";
import { api } from "data";
const {
  MAIN,
  OWNER,
  MANAGER,
  SUPPORT,
  PRIMARY_STAFF,
  SECONDARY_STAFF,
  TERTIARY_STAFF,
} = constants.STAFF_ROLES;

export const orgApiKeys = {
  member_name: "member_name",
  role_type: "role_type",
  is_active: "active",
  customer_count: "customer_count",
  revenue: "revenue",
  last_active_time: "last_active_time",
  contact: "contact",
  role_filter: "role",
  member_name_filter: "name",
  email_filter: "email",
  phone_number: "phone_number",
  offering_name: "listing_title",
  status_filter: "active",
  offering_type: "listing_type",
};

export const orgLabels = {
  member_name: "Member Name",
  document_title: "Organization - Exly Creator Tool",
  page_title: "Organization",
  page_description:
    "There are six different kinds of predefined roles. Each role type is a package of predetermined access settings, which can be updated at any time, at the role level or even at the individual staff level",
  add_member: "Add Member",
  role_type: "Role Name",
  is_active: "Status",
  customer_count: "Customers",
  revenue: "Revenue",
  last_active_time: "Last active",
  contact: "Contact",
  role_filter: "Role",
  member_name_filter: "Name",
  email_filter: "Email",
  phone_number: "Phone",
  offering_name: "Offering Name",
  status_filter: "Status",
  offering_type: "Offering Type",
};

export const permissionLabels = {
  read: "Read",
  write: "Write",
  read_all: "Complete Visibility",
  read_all_tooltip:
    "If 'Complete Visibility' is turned on for a member, they will see the data from that particular feature/option for all members of the organisation, though they will not be able to make any edits to that until the 'write' permission is toggled on as well.",
  communication_tooltip:
    "If any member has read permission for any communication, they will receive those communications as well as see them on the creator tool",
  blog_tooltip:
    "If the user have read permission for Blogs, they will be able to see blogs configured by all the members of the organisation",
};

export const memberActiveStatusDropdownOptions = [
  {
    id: "Active",
    label: "Active",
    value: true,
  },
  {
    id: "In-active",
    label: "Inactive",
    value: false,
  },
];

export const roleStatusMap = {
  [MAIN]: {
    label: "Main",
    background: "#0E0D0C",
  },
  [OWNER]: {
    label: "Owner",
    background: "#0E0D0C",
  },
  [MANAGER]: {
    label: "Manager",
    background: "#DD5B55",
  },
  [SUPPORT]: {
    label: "Support",
    background: "#0052CC",
  },
  [PRIMARY_STAFF]: {
    label: "Primary",
    background: "#493AB1",
  },
  [SECONDARY_STAFF]: {
    label: "Secondary",
    background: "#00B779",
  },
  [TERTIARY_STAFF]: {
    label: "Tertiary",
    background: "#FFAB00",
  },
};

export const orgMemberCardDetails = [
  {
    icon: OwnerIcon,
    title: "The Owner",
    description:
      'This will include you, the owner. By clicking on "add a member”, you can add someone as the co-owner for your organization. They will get the same access as you.',
    role: constants.STAFF_ROLES.OWNER,
    titleWithArticle: "an Owner",
  },
  {
    icon: ManagerIcon,
    title: "The Manager",
    description:
      "If you want someone to manage your business, while keeping the transactions and bank details safe from them - this access will be the perfect fit for that member.",
    role: constants.STAFF_ROLES.MANAGER,
    titleWithArticle: "a Manager",
  },
  {
    icon: SupportTeamIcon,
    title: "Support Team",
    description:
      "Have someone who manages the services and customer side of things? Give that member the support team access. This will be a good fit for members in - IT, customer support, business development, and like.",
    role: constants.STAFF_ROLES.SUPPORT,
    titleWithArticle: "a Support Staff",
  },
  {
    icon: PrimaryStaffIcon,
    title: "Primary Staff",
    description:
      "Give your staff the freedom to view, and manage their own offerings, bookings, customers and transactions, through this access type. Entire view of the creator tool will be built specific to them. keeping the other member/business safe.",
    role: constants.STAFF_ROLES.PRIMARY_STAFF,
    titleWithArticle: "a Primary Staff",
  },
  {
    icon: SecondaryStaffIcon,
    title: "Secondary Staff",
    description:
      "No editing, No creation, Only view? This would be the best choice for you. Members given this access will only be able to view stuff and not make any changes/additions. Entire view of the creator tool will be built specific to them.",
    role: constants.STAFF_ROLES.SECONDARY_STAFF,
    titleWithArticle: "a Secondary Staff",
  },
  {
    icon: TertiaryStaffIcon,
    title: "Tertiary Staff",
    description:
      "This access type would allow the member to only view their offerings, and nothing else on their creator tool.",
    role: constants.STAFF_ROLES.TERTIARY_STAFF,
    titleWithArticle: "a Tertiary Staff",
  },
];

export const staffRoleDropdownOptions = [
  {
    id: constants.STAFF_ROLES.OWNER,
    label: "Owner",
    value: constants.STAFF_ROLES.OWNER,
  },
  {
    id: constants.STAFF_ROLES.MANAGER,
    label: "Manager",
    value: constants.STAFF_ROLES.MANAGER,
  },
  {
    id: constants.STAFF_ROLES.SUPPORT,
    label: "Support",
    value: constants.STAFF_ROLES.SUPPORT,
  },
  {
    id: constants.STAFF_ROLES.PRIMARY_STAFF,
    label: "Primary",
    value: constants.STAFF_ROLES.PRIMARY_STAFF,
  },
  {
    id: constants.STAFF_ROLES.SECONDARY_STAFF,
    label: "Secondary",
    value: constants.STAFF_ROLES.SECONDARY_STAFF,
  },
  {
    id: constants.STAFF_ROLES.TERTIARY_STAFF,
    label: "Tertiary",
    value: constants.STAFF_ROLES.TERTIARY_STAFF,
  },
];

export const ADD_MEMBER_FORM_TOTAL_STEP_COUNT = 2;

export const ADD_MEMBER_FORM_STEP_TITLES = [
  "Member Details",
  "Role permission",
];

export const ADD_MEMBER_FORM_STEPS = [
  {
    title: "Member Details",
  },
  {
    title: "Role permission",
  },
];

export const memberPhotoPickerCrop = {
  unit: "%",
  width: 200,
  aspect: 1 / 1,
};

export const tabConfig = [
  {
    label: "Add Members",
    value: "add-members",
    path: `/${api.get_organisation_members}`,
  },
  {
    label: "Roles",
    value: "roles",
    path: "/organization/roles",
  },
];

export const profileNavigationTabs = [
  {
    label: "Business/Upcoming schedule",
    value: "schedule",
  },
  {
    label: "Activity Log",
    value: "logs",
  },
];
