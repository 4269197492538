import { makeStyles } from "@material-ui/core/styles";

const referral_status = {
  NOT_SIGNED_UP: { className: "text-danger", id: 0 },
  CONVERTED: { className: "text-success", id: 1 },
  Active: { className: "text-success", id: 2 },
  SIGNED_UP: { className: "text-success", id: 3 },
};

export default makeStyles((theme) => ({
  status: {
    color: theme?.palette?.basic?.white,
    fontSize: '14px',
    float: 'right',
    padding: '0 8px',
    borderRadius: '5px',
    overflow: 'hidden',
    width: 'max-content',
  },
  status__NOT_SIGNED_UP: {
    background: theme?.palette?.basic?.danger_red,
  },
  footer__NOT_SIGNED_UP: {
    background: "#FBEBEA !important",
  },
  status__CONVERTED: {
    background: theme?.palette?.fine_pine?.main,
  },
  footer__CONVERTED: {
    background: "#E5FFF7 !important",
  },
  status__Active: {
    background: theme?.palette?.fine_pine?.main,
  },
  footer__Active: {
    background: "#E5FFF7 !important",
  },
  status__SIGNED_UP: {
    background: theme?.palette?.primary.main,
  },
  footer__SIGNED_UP: {
    background: "#EEECF9 !important",
  },
  mobile_secondary_action: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.85)',
  },
  footer: {
    paddingRight: "12px",
  },
  empty_state_wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "32.34px",
    fontSize: "18px",
    color: theme?.palette?.secondary?.main,
  },
}))