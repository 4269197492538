// Third party imports
import React from "react";
import ActionFieldCustom from "ui/Fields/ActionFieldCustom";
import { RenderAvgRating } from "../../components/TableFields/TableFields";
import { module_constants } from "../../data/module_constants";

export const getTableConfig = (renderActions, renderFeedbackLink) => [
  {
    field: "listing_title",
    headerName: module_constants.listing_title,
    sortable: false,
    emptyField: "N/A",
  },
  {
    field: "listing_uuid",
    headerName: module_constants.feedback_link,
    valueFormatter: (record) => (
      <ActionFieldCustom
        record={record}
        sortable={false}
        source="listing_uuid"
        emptyText={"N/A"}
        ActionField={renderFeedbackLink}
        Nobutton={true}
      />
    ),
    sortable: false,
    emptyField: "N/A",
  },
  {
    field: "avg_rating",
    headerName: module_constants.avg_rating,
    valueFormatter: (record) => (
      <ActionFieldCustom
        record={record}
        sortable={false}
        source="avg_rating"
        emptyText={"N/A"}
        ActionField={RenderAvgRating}
        Nobutton={true}
      />
    ),
    sortable: false,
    hidden: true,
  },
  {
    field: "feedback_count",
    headerName: module_constants.feedback_count,
    sortable: false,
    emptyField: "N/A",
  },
  {
    field: "",
    headerName: module_constants.action,
    valueFormatter: (record) => (
      <ActionFieldCustom
        record={record}
        sortable={false}
        source="avg_rating"
        emptyText={"N/A"}
        ActionField={renderActions}
        Nobutton={true}
      />
    ),
    sortable: false,
    hidden: true,
    fixed: "right",
  },
];

export const getFilterConfig = () => {
  return [
    {
      source: "title",
      placeholder: module_constants.listing,
      type: "input",
    },
  ];
};

// export const tableChipsFormatter = (ratings, listings) => (filter, value) => {
//     switch (filter) {
//         case "rating":
//             const rating = ratings?.rating.find((rating) => rating.id == value);
//             return `Rating: ${rating?.title}`;
//         case "listing__uuid":
//             const offering = listings?.find((offering) => offering.id == value);
//             return offering ? `Offering: ${offering?.title}` : null;

//         default:
//             return undefined;
//     }
// };
