import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  progressRoot: {},
  titleContainer: {
    fontFamily: theme?.typography?.fontFamily,
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "& svg": {
      color: ({ getColor, label }) => getColor(theme, label),
    },
    "& span": {
      color: theme?.palette?.secondary?.main,
    },
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "4px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "17px",
  },
  tooltip: {
    color: ({ getColor, label }) => getColor(theme, label),
  },
}));
