// Third party imports
import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import {
  Button,
  ThemeProvider,
  creatorToolTheme,
} from "@my-scoot/component-library-legacy";

// Styles and icons
import useStyles from "./TableFields.styles";
import { ReactComponent as DesktopEmptyIcon } from "../../../../../assets/vectors/infoModal/Rating.svg";
import Rating from "react-rating";
import StarsRoundedIcon from "@material-ui/icons/StarRounded";
import EmptyStar from "@material-ui/icons/StarBorderRounded";

// Utils and providers
import { isInteger, is_empty } from "utils/validations";
import { useToggleState } from "utils/hooks";

// Components
import PreviewModal from "ui/modules/EmptyList/PreviewModal";
// import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { is_desktop, roundOff } from "utils/Utils";
import {
  module_constants,
  published_feedbacks,
  rating_icons,
} from "../../data/module_constants";
import { api } from "data";
import module_style from "../../Style.module.css";
import { useFeedbacks } from "../../data/useFeedbacks";

export const RatingField = ({ record, source, ratings }) => {
  const isDesktop = useMediaQuery("(min-device-width: 767px)");
  const isEmpty = is_empty(ratings);
  const item = isEmpty ? {} : ratings.rating[record[source] - 1];
  const classes = useStyles({
    isDesktop,
    getColor: module_constants.getColor,
    title: item.title,
  });

  if (isEmpty) return <></>;

  const RatingsIcon = rating_icons[record[source]]?.icon;

  return (
    <div className={classes.ratings_wrapper}>
      <RatingsIcon className={classes.ratings_icon} />
      <div className={classes.ratings_title}>{item.title}</div>
    </div>
  );
};

export const RemarksField = ({ record, ratings, type }) => {
  const isEmpty = is_empty(ratings);
  const item = isEmpty ? {} : ratings.rating[record.rating - 1];
  const classes = useStyles({ answer_color: item.colour });

  if (isEmpty) return <></>;
  const emptyState =
    type === "remark" ? (
      <div className={classes.remark_container}>N/A</div>
    ) : (
      "N/A"
    );
  if (is_empty(record?.feedback_data?.response)) return emptyState;
  let no_remark_check = record?.feedback_data?.response.filter(
    (item) => !is_empty(item.message)
  );
  if (is_empty(no_remark_check)) return emptyState;

  return (
    <>
      {ratings.question_bank[record.rating].map((item) => {
        let response = record.feedback_data?.response.filter(
          (answer) => answer.id == item.id
        );
        if (!is_empty(response[0]?.message)) {
          switch (type) {
            case "question":
              return <div>{item.title}</div>;
            case "answer":
              return (
                <div className={classes.remark_colored_answer}>
                  {response[0].message}
                </div>
              );
            default:
              return (
                <div className={classes.remark_container}>
                  <div>{item.title}</div>
                  <div className={classes.remark_answer}>
                    {response[0].message}
                  </div>
                </div>
              );
          }
        } else return emptyState;
      })}
    </>
  );
};

export const EmptyState = ({ preview_image }) => {
  const isDesktop = useMediaQuery("(min-device-width: 767px)");
  const classes = useStyles({ isDesktop });
  const [is_modal_active, show_modal, hide_modal] = useToggleState(false);
  const EmptyIcon = DesktopEmptyIcon;

  return (
    <>
      <ThemeProvider theme={creatorToolTheme}>
        <div className={classes.empty_wrapper}>
          <EmptyIcon />
          <div className={classes.empty_title}>No Feedback Received Yet .</div>
          <div className={classes.empty_content}>
            Host events and receive feedback from your customers. All the
            collected feedback will be listed here.{" "}
            <span onClick={show_modal} className={`action_text pointer`}>
              Sample User Feedback
            </span>
          </div>

          <Button
            onClick={() =>
              (window.location.href = `${window.location.origin}/#/${api.feedbacks.fetch_listing_feedbacks}`)
            }
          >
            Manage Feedback Form
          </Button>
        </div>
      </ThemeProvider>

      {is_modal_active && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={is_modal_active}
          onClose={hide_modal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <PreviewModal close={hide_modal} preview_image={preview_image} />
        </Modal>
      )}
    </>
  );
};

export const RenderAvgRating = (props) => {
  const { record = {}, source } = props;
  const { getFeedbackQuestions } = useFeedbacks(props);
  const classes = useStyles();

  if (is_empty(record[source])) return <>N/A</>;

  const overall_rating = roundOff(record[source], 2);
  const feedback_questions = getFeedbackQuestions();
  const ratings = feedback_questions?.data?.data?.rating;
  const color = !is_empty(ratings)
    ? ratings[parseInt(overall_rating) - 1]?.colour
    : "";

  return (
    <div
      className={`d-flex ${
        is_desktop ? "flex-column" : "flex-row"
      } justify-content-center align-items-center ${
        module_style.rating_column
      }`}
    >
      <div className={classes.rating_stars}>
        <Rating
          placeholderRating={overall_rating}
          readonly
          emptySymbol={<EmptyStar style={{ fill: color, fontSize: 24 }} />}
          placeholderSymbol={
            <StarsRoundedIcon style={{ fill: color, fontSize: 24 }} />
          }
        />
      </div>
      <div style={{ color: color }} className={`text-center`}>
        {overall_rating} / 5
      </div>
    </div>
  );
};

export const RenderCustomerRating = (props) => {
  const { record = {}, source } = props;
  const { getFeedbackQuestions } = useFeedbacks(props);
  const classes = useStyles();

  if (!isInteger(record[source])) return <>N/A</>;

  const overall_rating = record[source];
  const feedback_questions = getFeedbackQuestions();
  const ratings = feedback_questions?.data?.data?.rating;
  const color = !is_empty(ratings)
    ? ratings[parseInt(overall_rating) - 1]?.colour
    : "";

  return (
    <div
      className={`d-flex ${
        is_desktop ? "flex-column" : "flex-row"
      } justify-content-center align-items-start ${module_style.rating_column}`}
    >
      <div className={classes.rating_stars}>
        <Rating
          placeholderRating={overall_rating}
          readonly
          emptySymbol={<EmptyStar style={{ fill: color, fontSize: 24 }} />}
          placeholderSymbol={
            <StarsRoundedIcon style={{ fill: color, fontSize: 24 }} />
          }
        />
      </div>
    </div>
  );
};

export const RenderReviewStatus = ({ record = {}, source }) => {
  const status = published_feedbacks.statuses[record[source]];

  return (
    <div
      style={{ color: status.color, backgroundColor: status.backgroundColor }}
      className={`${module_style.status_chip} text-center`}
    >
      <span>{status.label}</span>
    </div>
  );
};
