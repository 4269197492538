import React, { useCallback, useState } from "react";
import MoreVert from "@material-ui/icons/MoreHoriz";
import module_style from "../Style.module.css";
import dataProvider from "data/dataProvider";
import api from "data/APIs";
import Popover from "@material-ui/core/Popover";
import { MobilePopover } from "@my-scoot/component-library-legacy";
import { Form } from "react-final-form";
import { ArrowForwardIosRounded as ArrowIcon } from "@material-ui/icons";
import { useStyles } from "./MoreActions.styles";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import QuestionsFormModal from "../QuestionsFormModal";

import DeleteIcon from "assets/vectors/delete_black.svg";
import EditIcon from "assets/vectors/edit_black.svg";
import { useNotifications } from "utils/hooks";
import ExlyDeleteModal from "common/Components/ExlyDeleteModal/ExlyDeleteModal";
import { addQuestionsValidations } from "schedule-v2/commonPages/Pricing.utils";
const initial_state = {
  questionObj: {},
};

const MoreActions = ({ record, show_more }) => {
  const classes = useStyles();
  const { notify } = useNotifications();
  const [show_delete, set_show_delete] = useState(false);

  const isDesktop = useDesktopMediaQuery();

  const [show_modal, set_show_modal] = React.useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = useCallback(
    (event) => setAnchorEl(event.currentTarget),
    [setAnchorEl]
  );

  const getAllQuestions = () => {
    // Using window.location.reload as history.go and userefresh doesn't work on iphone safari
    window.location.reload();
  };

  const EditQuestions = async (formState) => {
    const { questionObj } = formState;
    if (!addQuestionsValidations(questionObj, notify)) {
      return;
    }
    try {
      const response = await dataProvider.custom_request(
        api.questions.update_questions,
        "POST",
        {
          question_uuid: record?.uuid,
          updated_values: {
            ...questionObj,
          },
        }
      );
      if (response.status === 200) {
        getAllQuestions();
      }
    } catch (err) {
      console.error("error while adding question", err);
    }
  };

  const onClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  const OnEdit = () => {
    set_show_modal(true);
    onClose();
  };

  const OnDelete = async () => {
    try {
      const response = await dataProvider.custom_request(
        api.questions.delete_question,
        "POST",
        {
          question_uuid: record?.uuid,
        }
      );
      if (response.status === 200) {
        getAllQuestions();
      }
    } catch (err) {
      console.error("error while adding question", err);
    }
  };

  const content = (
    <div className={`${module_style.week_modal}`}>
      <div className={module_style.edit_delete} onClick={OnEdit}>
        <img src={EditIcon} alt="DeleteIcon" />
        <span>Edit</span>
      </div>
      <div
        className={module_style.edit_delete}
        onClick={() => {
          onClose();
          set_show_delete(true);
        }}
      >
        <img src={DeleteIcon} alt="DeleteIcon" />
        <span>Delete</span>
      </div>
    </div>
  );

  return (
    <span>
      <div onClick={onOpen}>
        {show_more ? (
          <div className={classes.moreCta}>
            <span>See More</span>
            <ArrowIcon />
          </div>
        ) : (
          <MoreVert aria-haspopup="true" className={"pointer"} />
        )}
      </div>
      {isDesktop ? (
        <Popover
          anchorEl={anchorEl}
          open={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          classes={{
            paper: module_style.popover_paper,
          }}
        >
          {content}
        </Popover>
      ) : (
        <MobilePopover open={anchorEl} onClose={onClose}>
          {content}
        </MobilePopover>
      )}

      <Form initialValues={initial_state} onSubmit={EditQuestions}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {show_modal && (
              <QuestionsFormModal
                title={"Edit Booking Question"}
                openModal={() => {
                  set_show_modal(false);
                }}
                isOpen={show_modal}
                closeModal={() => {
                  set_show_modal(false);
                }}
                record={record}
                addNewQuestion={EditQuestions}
                handleSubmit={handleSubmit}
                ctaText={"Save Changes"}
              />
            )}
          </form>
        )}
      </Form>
      <ExlyDeleteModal
        open={show_delete}
        onClose={() => {
          set_show_delete(false);
        }}
        onDelete={OnDelete}
        title="Confirm Delete"
        description="Are you sure you want to delete this question?"
      />
    </span>
  );
};

export default MoreActions;
