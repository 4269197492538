import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  moreCta: {
    display: "flex",
    alignItems: "center",
    gap: "4.1px",
    color: "#493AB1",
    marginBottom: "-5px",
    "& span": {
      fontSize: "14px",
      fontWeight: 500,
      textDecoration: "underline",
    },
    "& svg": {
      fontSize: "17.88px",
    },
  },
}));
